import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import SaveIcon from "@mui/icons-material/Save";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";

import { logar } from "../../store/modules/users/actions";
import api from "../../services/api";
import SnackBar from "../../components/SnackBar";
import LoadingFull from "../../components/LoadingFull";
import Dialog from "../../components/Dialog";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Label from "../../components/Label";
import {
  Area,
  BackgroundImage,
  LoginArea,
  ContentLogin,
  Card,
  CardSelecao,
  DialogArea,
  DialogButtons,
} from "./styled";

export default function AwayMode() {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});

  const [openDialogExitModeAway, setOpenDialogExitModeAway] = useState(false);
  const [visitsType, setVisitsType] = useState([]);
  const [visitTypeSelected, setVisitTypeSelected] = useState("");

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const userData = useSelector((state) => state.users);
  const netStatus = useSelector((state) => state.netStatus);

  const [loadingFull, setLoadingFull] = useState(false);

  const [password, setPassword] = useState("");

  const [correctors, setCorrectors] = useState([]);
  const [salesCompanies, setSalesCompanies] = useState([]);

  const [isAnotherCorrector, setIsAnotherCorrector] = useState(false);

  const getCorretorRoulette = async () => {
    setLoadingFull(true);

    await api
      .get(`/corrector/roulette`, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        setCorrectors(response.data);
      })
      .catch((err) => {
        console.log("ops erro: ", err);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const apiGetSalesCompanies = async () => {
    setLoadingFull(true);

    await api
      .get(`/salescompany`, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        setSalesCompanies(response.data);

        localStorage.setItem("salesCompany", JSON.stringify(response.data));
      })
      .catch((err) => {
        console.log("ops erro: ", err);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const getVisitTypes = async () => {
    setLoadingFull(true);

    await api
      .get(`/visit/type`, {
        params: { isnewlead: 0 },
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        setVisitsType(response.data);

        localStorage.setItem("visitsType", JSON.stringify(response.data));
      })
      .catch((err) => {
        console.log("ops erro: ", err);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const storageVisitAwayMode = (data) => {
    if (!visitTypeSelected) {
      setTypeSnackBar("error");
      setMessageSnackBar("Selecione um tipo de visita");
      setOpenSnackBar(true);

      return null;
    }

    let listVisitsAwayMode = [];

    if (localStorage.getItem("visitsAwayMode"))
      listVisitsAwayMode = JSON.parse(localStorage.getItem("visitsAwayMode"));

    let newListVisitsAwayMode = [
      ...listVisitsAwayMode,
      {
        uuid: uuid(),
        name: data?.name,
        email: data?.email,
        corrector: {
          RouletteId: null,
          name: data?.corrector?.name || null,
          CorrectorId: data?.correctorName ? null : data?.corrector?.id,
          newCorrectorName: data?.correctorName || null,
          salesCompanyId: data?.salesCompany?.id || null,
        },
        userId: userData?.id,
        visitsType: visitTypeSelected,
      },
    ];

    localStorage.setItem(
      "visitsAwayMode",
      JSON.stringify(newListVisitsAwayMode)
    );

    reset();
    setValue("corrector", "");
    document.getElementById("formVisit").scrollTo(0, 0);
    document.getElementById("name").focus();
    setIsAnotherCorrector(false);
    setVisitTypeSelected("");
    setTypeSnackBar("success");
    setMessageSnackBar("Cliente salvo");
    setOpenSnackBar(true);
  };

  const desactiveModeAway = async (event) => {
    event.preventDefault();

    if (!password) {
      setTypeSnackBar("error");
      setMessageSnackBar("Campo necessário");
      setOpenSnackBar(true);
      return null;
    }

    setLoadingFull(true);
    setOpenDialogExitModeAway(false);

    await api
      .put(
        "/user/awaymode/desactive",
        { password: password },
        { headers: { Authorization: `Bearer ${userData.token}` } }
      )
      .then((response) => {
        const newUserData = {
          ...userData,
          awayMode: false,
          redirect: "/visitas-modo-ausente",
        };

        localStorage.setItem("user", JSON.stringify(newUserData));

        dispatch(logar(newUserData));
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setPassword("");
        setLoadingFull(false);
      });
  };

  const verifyNetStatusForVisitsTypes = () => {
    if (netStatus) {
      getVisitTypes();
    } else {
      const list = localStorage.getItem("visitsType");
      setVisitsType(JSON.parse(list));
    }
  };

  const verifyNetStatusForSalesCompanies = () => {
    if (netStatus) {
      apiGetSalesCompanies();
    } else {
      const list = localStorage.getItem("salesCompany");
      setVisitsType(JSON.parse(list));
    }
  };

  const verifyNetStatusForGetCorrectors = () => {
    if (netStatus) {
      getCorretorRoulette();
    } else {
      const list = localStorage.getItem("corrector");

      setCorrectors(JSON.parse(list));
    }
  };

  useEffect(() => {
    verifyNetStatusForVisitsTypes();
    verifyNetStatusForGetCorrectors();
    verifyNetStatusForSalesCompanies();
  }, []);

  return (
    <>
      <Area>
        <BackgroundImage />
        <LoginArea id="formVisit" style={{ flex: 1 }}>
          <form onSubmit={handleSubmit(storageVisitAwayMode)}>
            <ContentLogin>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "2em",
                  alignItems: "center",
                }}
              >
                <p style={{ paddingRight: 10, fontSize: 16, color: "#828282" }}>
                  {userData.email}
                </p>

                <Button
                  type="button"
                  onClick={() => setOpenDialogExitModeAway(true)}
                  icon={<ArrowForwardIcon />}
                >
                  Voltar ao sistema
                </Button>
              </Box>

              <h1>
                <b style={{ textTransform: "capitalize" }}>
                  {userData.name.split(" ")[0]}
                </b>{" "}
                está ausente no momento
              </h1>
              <h6>
                Para auxiliar na organização da loja, preencha as informações do
                cliente abaixo
              </h6>

              <Label>Nome</Label>

              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    autoFocus
                    id="name"
                    error={!!errors.name}
                    ref={ref}
                    placeholder="Nome do cliente"
                  />
                )}
              />

              <Label>Email</Label>

              <Controller
                name="email"
                control={control}
                rules={{ required: false }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    error={!!errors.email}
                    type="email"
                    inputProps={{
                      inputMode: "email",
                    }}
                    ref={ref}
                    placeholder="Email do cliente"
                  />
                )}
              />

              <Label style={{ paddingBottom: 5 }}>Corretor</Label>

              <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                <Controller
                  name="corrector"
                  control={control}
                  rules={{ required: !isAnotherCorrector }}
                  render={({ field: { onChange, value }, ref }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || null}
                      ref={ref}
                      disabled={isAnotherCorrector}
                      fullWidth
                      noOptionsText="Nenhum corretor encontrado"
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) =>
                        Number(option.id) === Number(value.id)
                      }
                      options={correctors}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Selecione o corretor"
                          error={!!errors["corrector"]}
                          {...params}
                        />
                      )}
                    />
                  )}
                />

                <Button
                  sx={{
                    minWidth: "max-content!important",
                    backgroundColor: `${
                      isAnotherCorrector ? "var(--main-color)" : "#EBADAE"
                    }!important`,
                    color: `${
                      isAnotherCorrector ? "#FFFFFF" : "#000000"
                    }!important`,
                  }}
                  onClick={(e) => setIsAnotherCorrector((prev) => !prev)}
                >
                  Outro corretor
                </Button>
              </div>

              {isAnotherCorrector && (
                <>
                  <Label>Nome corretor</Label>

                  <Controller
                    name="correctorName"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field, ref }) => (
                      <Input
                        {...field}
                        error={Boolean(errors.correctorName)}
                        type="text"
                        ref={ref}
                        placeholder="Nome do corretor"
                      />
                    )}
                  />

                  <Label>Empresa de vendas</Label>

                  <Controller
                    name="salesCompany"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, ref }) => (
                      <Autocomplete
                        {...field}
                        onChange={(event, newValue) => {
                          field.onChange(newValue);
                        }}
                        fullWidth
                        noOptionsText="Nenhuma empresa de vendas encontrada"
                        sx={{
                          pt: 1,
                          mb: 2,
                        }}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value;
                        }}
                        options={salesCompanies}
                        getOptionLabel={(option) => option?.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione a empresa de vendas"
                            error={Boolean(errors.salesCompany)}
                          />
                        )}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    )}
                  />
                </>
              )}

              <Label>Tipo de visita</Label>

              {visitsType?.length > 0 &&
                visitsType.map((item) => (
                  <Card key={item.id}>
                    <Grid container spacing={0}>
                      <Grid item md={2} xs={3}>
                        <div
                          style={{
                            display: "flex",
                            width: "fit-content",
                            background: "#F8F8F8",
                            padding: ".5em",
                            borderRadius: 50,
                          }}
                        >
                          <CalendarMonthIcon />
                        </div>
                      </Grid>
                      <Grid item md={6} xs={9}>
                        <h6>{item.title}</h6>
                        <p>{item.description}</p>
                      </Grid>
                      <Grid item mt={{ md: 0, xs: 1 }} md={4} xs={12}>
                        <CardSelecao
                          onClick={() => setVisitTypeSelected(item)}
                          active={visitTypeSelected.id === item.id}
                        >
                          {visitTypeSelected.id === item.id ? (
                            <CheckCircleIcon />
                          ) : (
                            <div />
                          )}

                          <span>
                            {visitTypeSelected.id === item.id
                              ? "Selecionado"
                              : "Selecionar"}
                          </span>
                        </CardSelecao>
                      </Grid>
                    </Grid>
                  </Card>
                ))}

              <div
                style={{
                  marginBottom: "1em",
                  marginTop: "1em",
                  display: "flex",
                }}
              >
                <Box sx={{ flex: 1, mb: 3 }}>
                  <Button
                    sx={{ width: { md: "max-content", xs: "100%!important" } }}
                    type="submit"
                    icon={<SaveIcon />}
                  >
                    Salvar
                  </Button>
                </Box>
              </div>
            </ContentLogin>
          </form>
        </LoginArea>
      </Area>

      <Dialog
        width={450}
        open={openDialogExitModeAway}
        setOpen={setOpenDialogExitModeAway}
      >
        <DialogArea>
          <form autoComplete="off" onSubmit={desactiveModeAway}>
            <h1>Confirmação</h1>
            <p>Deseja sair do modo ausente? confirme a senha</p>

            <Label style={{ marginTop: "1em" }}>Senha</Label>

            <Input
              value={password}
              autoFocus
              name="field1"
              type="text"
              id="field1"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Digite sua senha"
              inputProps={{
                autoComplete: "off",
                style: { WebkitTextSecurity: "disc" },
              }}
            />

            <DialogButtons>
              <button type="submit">Entrar</button>

              <button
                type="button"
                onClick={() => setOpenDialogExitModeAway(false)}
              >
                Cancelar
              </button>
            </DialogButtons>
          </form>
        </DialogArea>
      </Dialog>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />
    </>
  );
}
