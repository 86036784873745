import { useState } from "react";
import { Box } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import Input from "../../../components/Input";
import Button from "../../../components/Button";
import apiCpm2024 from "../../../services/apiCpm2024";
import { inputHandleCpfChange } from "../../../utils/masksInputs";

export default function StepOne({
  setStep,
  setOpenSnackBar,
  setMessageSnackBar,
  setTypeSnackBar,
  externToken,
  setExternToken,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const userData = useSelector((state) => state.users);

  const [loading, setLoading] = useState(false);

  const [hasPrize, sethasPrize] = useState(false);
  const [received, setReceived] = useState(false);
  const [completeDocument, setCompleteDocument] = useState(false);

  const apiHandleVerifyWinner = (data) => {
    setLoading(true);

    apiCpm2024
      .post("/visitas/check-cpf", {
        cpf: data.cpf,
      })
      .then((response) => {
        setExternToken(response.data.token);

        sethasPrize(true);

        if (response.data.received === true) {
          setReceived(true);
        }

        if (response.data.completed_upload === false) {
          setCompleteDocument(false);
        }
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setTypeSnackBar("error");
        setMessageSnackBar(
          error?.response?.data?.message || "Tente novamente mais tarde!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const apiConfirmPrize = () => {
    setLoading(true);

    apiCpm2024
      .post(
        "/visitas/complete-prize",
        {
          atendent_id: userData?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${externToken}`,
          },
        }
      )
      .then((response) => {
        setReceived(true);
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setTypeSnackBar("error");
        setMessageSnackBar(
          error?.response?.data?.message || "Tente novamente mais tarde!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (hasPrize) {
    return (
      <Box>
        <p>Este participante tem direito ao prêmio</p>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            type="submit"
            sx={{ mt: 2 }}
            disabled={received === true ? true : false}
            onClick={apiConfirmPrize}
          >
            {received ? "Prêmio entregue" : "Confirmar recebimento do prêmio"}
          </Button>

          <Button
            type="submit"
            sx={{ mt: 2 }}
            disabled={completeDocument === true ? true : false}
            onClick={() => setStep(2)}
          >
            {completeDocument ? "Termo já foi enviado" : "Enviar termo"}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <form onSubmit={handleSubmit(apiHandleVerifyWinner)}>
      <p style={{ margin: 0 }}>
        1. Digite o CPF para verificar se ele é um ganhador
      </p>

      <Controller
        name="cpf"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field, ref }) => (
          <Input
            {...field}
            error={!!errors.cpf}
            ref={ref}
            sx={{
              mt: "8px",
            }}
            onChange={(e) => inputHandleCpfChange(e, field.onChange)}
            helperText={errors.cpf ? "Campo obrigatório" : ""}
            placeholder="Digite o CPF"
          />
        )}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button type="submit" sx={{ mt: 2 }} disabled={loading}>
          {loading ? "Verificando..." : "Verificar"}
        </Button>
      </Box>
    </form>
  );
}
