import { Button } from "@mui/material";
import styled from "styled-components";

export const ButtonCustom = styled(Button)` 
    background-color: var(--main-color)!important;
    color: #FFFFFF!important;
    position: fixed!important;
    bottom: 2rem;
    right: 2rem;
    padding: .75em 1.5em!important;
    border-radius: 50px!important;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px!important;
    text-transform: none!important;

    :hover{
        opacity: .9;
        outline: 2px solid lightgrey;
    }
`;