import { useEffect, useState } from "react";
import { Autocomplete, Box, Grid, MenuItem, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import Button from "../../../components/Button";
import Dialog from "../../../components/Dialog";
import Input from "../../../components/Input";
import Label from "../../../components/Label";
import Select from "../../../components/Select";
import api from "../../../services/api";
import { DialogArea, DialogButtons } from "./styled";

export default function ModalChangeCorrector({
  open,
  setOpen,
  setLoadingFull,
  visitId,
  apiGetAllVisits,
  setTypeSnackBar,
  setMessageSnackBar,
  setOpenSnackBar,
  correctorVisit,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const userData = useSelector((state) => state.users);

  const [isAnotherCorrector, setIsAnotherCorrector] = useState(false);

  const [correctors, setCorrectors] = useState([]);
  const [salesCompanies, setSalesCompanies] = useState([]);

  const apiGetAllCorretors = async () => {
    setLoadingFull(true);

    await api
      .get(`/corrector/roulette`, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        setCorrectors(response.data);
      })
      .catch((err) => {
        console.log("ops erro: ", err);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const apiGetSalesCompanies = async () => {
    setLoadingFull(true);

    await api
      .get(`/salescompany`, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        setSalesCompanies(response.data);
        localStorage.setItem("salesCompany", JSON.stringify(response.data));
      })
      .catch((err) => {
        console.log("ops erro: ", err);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const apiUpdateVisitCorrector = async (data) => {
    if (!data?.corrector?.id && !isAnotherCorrector) {
      setTypeSnackBar("info");
      setMessageSnackBar("Selecione outro corretor para atualizar");
      setOpenSnackBar(true);

      return null;
    }

    setLoadingFull(true);

    setOpen(false);

    let newData = {
      CorrectorId: data?.corrector?.id,
      CorrectorName: data?.correctorName,
      CorrectorSalesCompany: data?.salesCompany,
    };

    await api
      .put(`/visit/${visitId}`, newData, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        apiGetAllVisits();
        apiGetAllCorretors();

        setTypeSnackBar("success");
        setMessageSnackBar("O Corretor da visita foi atualizado com sucesso");
        setOpenSnackBar(true);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setValue("corrector", null);
        setValue("correctorName", "");
        setValue("salesCompany", "");
        setIsAnotherCorrector(false);
        setLoadingFull(false);
      });
  };

  useEffect(() => {
    if (open) {
      apiGetSalesCompanies();
      apiGetAllCorretors();
      setIsAnotherCorrector(false);
      setValue("corrector", correctorVisit.name || null);
    }
  }, [open, correctorVisit.name]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Dialog width={450} open={open} setOpen={setOpen}>
        <DialogArea>
          <h1>Atualizar corretor</h1>
          <p>Informe qual corretor dessa visita</p>

          <form onSubmit={handleSubmit(apiUpdateVisitCorrector)}>
            <Grid pt={1} mb={2} container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="corrector"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name }, ref }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || null}
                      ref={ref}
                      name={name}
                      disabled={isAnotherCorrector}
                      fullWidth
                      noOptionsText="Nenhum corretor encontrado"
                      getOptionLabel={(option) => option?.name}
                      options={correctors}
                      isOptionEqualToValue={(e, v) => {
                        return e.name === v.name;
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Selecione o corretor"
                          error={!!errors["corrector"]}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Box>
              <Button
                fullWidth
                sx={{
                  minWidth: "100%",
                  marginBottom: 1,
                  backgroundColor: `${
                    isAnotherCorrector ? "var(--main-color)" : "#EBADAE"
                  }!important`,
                  color: `${
                    isAnotherCorrector ? "#FFFFFF" : "#000000"
                  }!important`,
                }}
                onClick={(e) => setIsAnotherCorrector((prev) => !prev)}
              >
                Outro corretor
              </Button>
            </Box>

            {isAnotherCorrector && (
              <>
                <Label>Nome corretor</Label>

                <Controller
                  name="correctorName"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      error={!!errors.correctorName}
                      type="text"
                      sx={{ mb: 1 }}
                      fullWidth
                      ref={ref}
                      placeholder="Nome do corretor"
                    />
                  )}
                />

                <Label>Empresa de vendas</Label>

                <Controller
                  name="salesCompany"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <Select
                      {...field}
                      displayEmpty
                      sx={{ mb: 2 }}
                      fullWidth
                      error={!!errors.salesCompany}
                      ref={ref}
                    >
                      <MenuItem value={""}>
                        <em>Selecione a Empresa de vendas</em>
                      </MenuItem>
                      {salesCompanies?.length > 0 &&
                        salesCompanies?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </>
            )}

            <DialogButtons>
              <button type="submit">Atualizar corretor</button>

              <button type="button" onClick={() => setOpen(false)}>
                Cancelar
              </button>
            </DialogButtons>
          </form>
        </DialogArea>
      </Dialog>
    </>
  );
}
