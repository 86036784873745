import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Box, Divider, Grid, IconButton, MenuItem } from "@mui/material";

import { HeaderDrawer } from "./styled";
import Drawer from "../../../../components/Drawer";
import Label from "../../../../components/Label";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import Button from "../../../../components/Button";
import api from "../../../../services/api";
import SnackBar from "../../../../components/SnackBar";

export default function DrawerUpdateUser({
  state,
  setState,
  setLoadingFull,
  apiGetAllUsers,
  userSelected,
}) {
  const userData = useSelector((state) => state.users);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const apiUpdateUser = async (data) => {
    setLoadingFull(true);

    const userInfo = {
      name: data.name,
      email: data.email,
      admin: data.type,
    };

    await api
      .put(`/user/${userSelected.id}`, userInfo, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then(() => {
        apiGetAllUsers();
        reset();
        setState(false);
        setTypeSnackBar("success");
        setMessageSnackBar("Usuário atualizado com sucesso!");
        setOpenSnackBar(true);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  useEffect(() => {
    setValue("name", userSelected.name);
    setValue("email", userSelected.email);
    setValue("type", userSelected.admin ? 1 : 0);
  }, [userSelected]);

  return (
    <>
      <Drawer state={state} setState={setState}>
        <HeaderDrawer>
          <div>
            <EditIcon />

            <h5>Editando Usuário</h5>
          </div>

          <IconButton
            onClick={() => setState({ right: false })}
            sx={{ padding: 0.5 }}
          >
            <CloseIcon />
          </IconButton>
        </HeaderDrawer>

        <Divider sx={{ mt: 1 }} />

        <Grid container spacing={{ md: 2, xs: 0 }}>
          <Grid item md={6} xs={12}>
            <Label>Nome</Label>

            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field, ref }) => (
                <Input
                  {...field}
                  error={!!errors.name}
                  ref={ref}
                  placeholder="Digite o nome"
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Label>Email</Label>

            <Controller
              name="email"
              control={control}
              rules={{
                required: "Campo necessário",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email inválido",
                },
              }}
              defaultValue=""
              render={({ field, ref }) => (
                <Input
                  {...field}
                  error={!!errors.email}
                  ref={ref}
                  placeholder="Digite o email"
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Label>Tipo</Label>

            <Controller
              name="type"
              control={control}
              rules={{
                required: "Campo necessário",
              }}
              defaultValue=""
              render={({ field, ref }) => (
                <Select {...field} displayEmpty error={!!errors.type} ref={ref}>
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value={1}>Administrador</MenuItem>
                  <MenuItem value={0}>Atendente</MenuItem>
                </Select>
              )}
            />
          </Grid>
        </Grid>

        <Divider sx={{ mt: 3, mb: 3 }} />

        <Box sx={{ textAlign: "end" }}>
          <Button onClick={handleSubmit(apiUpdateUser)} icon={<EditIcon />}>
            Alterar
          </Button>
        </Box>
      </Drawer>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />
    </>
  );
}
