import styled from "styled-components";

export const HeaderDrawer = styled.div` 
    display: flex;
    align-items: center;
    justify-content: space-between;

    div{
        display: flex;
        align-items: center;
    }

    h5{
        font-size: 20px;
        padding-left: .7em!important;
        margin: 0;
        color: #535353;
    }
    
    svg{
        color: #535353;
        font-size: 27px;
    }
`;