import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

export default function Drawer({ children, state, setState }) {
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const anchor = window.innerWidth > 468 ? "right" : "bottom";

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={Boolean(state)}
      onClose={toggleDrawer(anchor, false)}
      onOpen={toggleDrawer(anchor, true)}
    >
      <Box
        sx={{
          padding: "1.3em",
          height: "100%",
          width:
            anchor === "top" || anchor === "bottom"
              ? "auto"
              : window.innerWidth / 3,
        }}
        role="presentation"
      >
        {children}
      </Box>
    </SwipeableDrawer>
  );
}
