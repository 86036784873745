import { Link } from "react-router-dom";

import { ButtonCustom } from "./styled";

export default function FloatingButton({ link, children }) {
  return (
    <Link to={link || "/"}>
      <ButtonCustom>{children}</ButtonCustom>
    </Link>
  );
}
