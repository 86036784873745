import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import Dialog from "../../Dialog";
import Table from "../../Table";
import api from "../../../services/api";
import { Title } from "./styled";
import Input from "../../Input";
import Label from "../../Label";
import Button from "../../Button";

export default function ModalRoulette({ open, setOpen }) {
  const [correctors, setCorrectors] = useState([]);

  const [filterName, setFilterName] = useState("");

  const userData = useSelector((state) => state.users);

  const [loading, setLoading] = useState(false);

  const getCorretorRoulette = () => {
    setLoading(true);

    setCorrectors([]);

    api
      .get(`/corrector/roulette`, {
        params: {
          isroulette: true,
          name: filterName || null,
        },
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        setCorrectors(response.data);
      })
      .catch((err) => {
        console.log("ops erro: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formDateToHour = (date) => {
    return new Intl.DateTimeFormat("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(date));
  };

  const handleFilter = (e) => {
    e.preventDefault();

    getCorretorRoulette();
  };

  useEffect(() => {
    if (open) {
      getCorretorRoulette();
    }

    return () => {
      setFilterName("");
    };
  }, [open]);

  return (
    <Dialog width={450} open={open} setOpen={setOpen} sx={{}}>
      <Box
        sx={{
          mb: "0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title>Roleta do dia</Title>

        <Tooltip title="Fechar" disableInteractive>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <form onSubmit={handleFilter}>
        <Grid mt={"4px"} mb={"8px"} container spacing={2}>
          <Grid item md={8} xs={12}>
            <Label style={{ marginTop: "0" }}>Nome corretor</Label>

            <Input
              type="text"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              placeholder="Pesquisar pelo nome do corretor"
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item md={4} xs={12} sx={{ display: "flex", alignItems: "end" }}>
            <Button
              fullWidth
              type="submit"
              sx={{ height: "40px!important", width: "100%!important" }}
            >
              Pesquisar
            </Button>
          </Grid>
        </Grid>
      </form>

      <Table isTableHeaderFixed={true}>
        <thead>
          <tr>
            <th>Corretor nome</th>
            <th>Empresa de vendas</th>
            <th>Gerente de vendas</th>
            <th>Qtd. atendimento</th>
            <th>Horário último atendimento</th>
          </tr>
        </thead>
        <tbody>
          {correctors?.length > 0 &&
            correctors?.map((item) => (
              <tr key={item.id}>
                <td>{item.Corrector?.name}</td>
                <td>{item.Corrector?.SalesCompany?.name}</td>
                <td>{item.Corrector?.salesManager}</td>
                <td>{item.attended}</td>
                <td>
                  {item?.Corrector?.Visits.length === 0 ? (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "100%",
                        color: "#817b7b",
                        fontWeight: "400",
                      }}
                    >
                      Não teve atendimento
                    </Typography>
                  ) : (
                    formDateToHour(
                      item?.Corrector?.Visits[item.Corrector.Visits.length - 1]
                        ?.createdAt
                    )
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {loading && (
        <Box sx={{ display: "flex", my: "40px", justifyContent: "center" }}>
          <CircularProgress size={28} />
        </Box>
      )}

      {correctors?.length === 0 && !loading && (
        <div style={{ textAlign: "center" }}>
          <p>não foi encontrado nenhum corretor</p>
        </div>
      )}
    </Dialog>
  );
}
