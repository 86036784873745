import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from "@mui/icons-material/Save";
import NumberFormat from "react-number-format";

import {
  Area,
  BackgroundImage,
  Card,
  CardSelecao,
  ContentLogin,
  LoginArea,
  RegisterDescription,
  RegisterTitle,
} from "./styled";
import LoadingFull from "../../components/LoadingFull";
import SnackBar from "../../components/SnackBar";
import api from "../../services/api";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Label from "../../components/Label";

export default function ClientPublic() {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({});

  const params = useParams();

  const [visitsType, setVisitsType] = useState([]);
  const [visitTypeSelected, setVisitTypeSelected] = useState("");

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const userData = useSelector((state) => state.users);
  const netStatus = useSelector((state) => state.netStatus);

  const [loadingFull, setLoadingFull] = useState(true);

  const [store, setStore] = useState({});

  const [isCompletedRegister, setIsCompletedRegister] = useState(false);

  const getVisitTypes = async () => {
    setLoadingFull(true);

    await api
      .get(`/visit/type`, { params: { isnewlead: 0 } })
      .then((response) => {
        setVisitsType(response.data);

        localStorage.setItem("visitsType", JSON.stringify(response.data));
      })
      .catch((err) => {
        console.log("ops erro: ", err);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const apiCreateLeadAndVisitByClient = (data) => {
    if (!visitTypeSelected) {
      setTypeSnackBar("error");
      setMessageSnackBar("Selecione um tipo de visita");
      setOpenSnackBar(true);

      return null;
    }

    const newVisit = {
      name: data?.name,
      email: data?.email,
      phone: data?.phone
        ?.replace("(", "")
        .replace(")", "")
        .replaceAll(" ", "")
        .replace("-", ""),
      visitTypeId: visitTypeSelected?.id,
      storeId: store.id,
    };

    api
      .post("/client/visit", newVisit)
      .then((response) => {
        setOpenSnackBar(true);
        setTypeSnackBar("success");
        setMessageSnackBar("Registro realizado com sucesso!");

        setIsCompletedRegister(true);
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setTypeSnackBar("error");
        setMessageSnackBar(
          "Não foi possível realizar o registro, tente novamente mais tarde!"
        );
      })
      .finally(() => {
        reset();
        setVisitTypeSelected("");
      });
  };

  const apiGetStore = () => {
    setLoadingFull(true);

    api
      .get(`/store/${params.storeSlug}`)
      .then((response) => {
        setStore(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const verifyNetStatusForVisitsTypes = () => {
    if (netStatus) {
      getVisitTypes();
    } else {
      const list = localStorage.getItem("visitsType");
      setVisitsType(JSON.parse(list));
    }
  };

  useEffect(() => {
    verifyNetStatusForVisitsTypes();
    apiGetStore();
  }, []);

  if (!store && !loadingFull) {
    return (
      <Area>
        <BackgroundImage />

        <LoginArea id="formVisit" style={{ flex: 1 }}>
          <ContentLogin>
            <Box sx={{ textAlign: "center", py: { md: 0, xs: 8 } }}>
              <img
                width="350"
                height="350"
                src="/assets/images/boxEmpty.svg"
                alt=""
              />

              <RegisterTitle>Loja não encontrada</RegisterTitle>
              <RegisterDescription>
                Não encontramos essa loja!
              </RegisterDescription>
            </Box>
          </ContentLogin>
        </LoginArea>
      </Area>
    );
  }

  return (
    <>
      <Area>
        <BackgroundImage />

        <LoginArea id="formVisit" style={{ flex: 1 }}>
          <form onSubmit={handleSubmit(apiCreateLeadAndVisitByClient)}>
            <ContentLogin>
              {isCompletedRegister ? (
                <>
                  <Box sx={{ textAlign: "center", py: { md: 0, xs: 8 } }}>
                    <img
                      width="350"
                      height="350"
                      src="/assets/images/enthusiastic-not-css.svg"
                      alt=""
                    />

                    <RegisterTitle>Visita registrada</RegisterTitle>
                    <RegisterDescription>
                      Obrigado pela sua visita, volte sempre!
                    </RegisterDescription>
                  </Box>
                </>
              ) : (
                <>
                  <h1>Olá, bem-vindo a nossa {store?.name || "loja"}</h1>
                  <h6>
                    Preencha as informações abaixo, para registrar a sua visita.
                  </h6>

                  <Label>Nome Completo</Label>

                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field, ref }) => (
                      <Input
                        {...field}
                        autoFocus
                        id="name"
                        error={!!errors.name}
                        ref={ref}
                        placeholder="Digite o seu nome completo"
                      />
                    )}
                  />

                  <Label>E-mail</Label>

                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field, ref }) => (
                      <Input
                        {...field}
                        error={!!errors.email}
                        type="email"
                        ref={ref}
                        placeholder="Digite o seu e-mail"
                      />
                    )}
                  />

                  <Label>Telefone</Label>

                  <Controller
                    name="phone"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field, ref }) => (
                      <NumberFormat
                        customInput={Input}
                        {...field}
                        error={!!errors.phone}
                        ref={ref}
                        inputMode="numeric"
                        pattern="[0-9],*"
                        placeholder="Digite o seu telefone"
                        format="(##) #####-####"
                      />
                    )}
                  />

                  <Label>Tipo de visita</Label>

                  {visitsType?.length > 0 &&
                    visitsType.map((item) => (
                      <Card key={item.id}>
                        <Grid container spacing={0}>
                          <Grid item lg={2} md={3} sm={2} xs={3}>
                            <div
                              style={{
                                display: "flex",
                                width: "fit-content",
                                background: "#F8F8F8",
                                padding: ".5em",
                                borderRadius: 50,
                              }}
                            >
                              <CalendarMonthIcon />
                            </div>
                          </Grid>
                          <Grid item lg={6} md={9} sm={6} xs={9}>
                            <h6>{item.title}</h6>
                            <p>{item.description}</p>
                          </Grid>
                          <Grid
                            mt={{ lg: 0, md: 1, xs: 2 }}
                            item
                            lg={4}
                            md={12}
                            sm={4}
                            xs={12}
                          >
                            <CardSelecao
                              onClick={() => setVisitTypeSelected(item)}
                              active={visitTypeSelected.id === item.id}
                            >
                              {visitTypeSelected.id === item.id ? (
                                <CheckCircleIcon />
                              ) : (
                                <div />
                              )}

                              <span>
                                {visitTypeSelected.id === item.id
                                  ? "Selecionado"
                                  : "Selecionar"}
                              </span>
                            </CardSelecao>
                          </Grid>
                        </Grid>
                      </Card>
                    ))}

                  <div
                    style={{
                      marginBottom: "1em",
                      marginTop: "1em",
                      display: "flex",
                    }}
                  >
                    <Box sx={{ flex: 1, mb: 3 }}>
                      <Button
                        sx={{
                          width: { md: "max-content", xs: "100%!important" },
                        }}
                        type="submit"
                        icon={<SaveIcon />}
                      >
                        Registrar
                      </Button>
                    </Box>
                  </div>
                </>
              )}
            </ContentLogin>
          </form>
        </LoginArea>
      </Area>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />
    </>
  );
}
