import { useEffect, useState } from "react";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
import { Box, IconButton, Pagination, Tooltip } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import HailIcon from "@mui/icons-material/Hail";
import StoreIcon from "@mui/icons-material/Store";

import Header from "../../components/Header";
import { TextTotalItems } from "./styled";
import Button from "../../components/Button";
import SnackBar from "../../components/SnackBar";
import LoadingFull from "../../components/LoadingFull";
import api from "../../services/api";
import Input from "../../components/Input";
import ModalLeadPlus from "./ModalLeadPlus";
import Table from "../../components/Table";
import ModalEditDate from "./ModalEditDate";
import ModalSelectEnterprise from "./ModalSelectEnterprise";
import ModalChangeCorrector from "./ModalChangeCorrector";

export default function AttendanceVisits() {
  const userData = useSelector((state) => state.users);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success | info

  const [loadingFull, setLoadingFull] = useState(false);

  const [visits, setVisits] = useState([]);
  const [lastPage, setLastPage] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);

  const [openModalLead, setOpenModalLead] = useState(false);

  const [dayStartFilter, setDayStartFilter] = useState("");
  const [dayEndFilter, setDayEndFilter] = useState("");

  const [leadId, setLeadId] = useState("");
  const [selectedLead, setSelectedLead] = useState({});

  const [isModalEnterpriseOpen, setIsModalEnterpriseOpen] = useState(false);

  const [selectedIdVisit, setSelectedIdVisit] = useState("");

  const [isModalCorrectorOpen, setIsModalCorrectorOpen] = useState(false);

  const [isModalVisitDateOpen, setIsModalVisitDateOpen] = useState(false);
  const [visitDate, setVisitDate] = useState("");
  const [enterpriseVisit, setEnterpriseVisit] = useState("");
  const [correctorVisit, setCorrectorVisit] = useState({ id: "", name: "" });

  const apiGetAllVisits = async (
    page = 1,
    dayStartFilter = "",
    dayEndFilter = ""
  ) => {
    setLoadingFull(true);

    await api
      .get(
        `/visit/user?page=${page}
            ${dayStartFilter && "&daystart=" + dayStartFilter}
            ${dayEndFilter && "&dayend=" + dayEndFilter}
        `,
        { headers: { Authorization: `Bearer ${userData.token}` } }
      )
      .then((response) => {
        setLastPage(response.data.totalPages);
        setTotalItems(response.data.totalItems);
        setVisits(response.data.visits);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const handleChange = (event, value) => {
    apiGetAllVisits(value, dayStartFilter, dayEndFilter);
    setPage(value);
  };

  const handleResetFilter = () => {
    setDayStartFilter("");
    setDayEndFilter("");
    apiGetAllVisits(page);
  };

  const verifyLeadAdditionalQuestionary = (lead) => {
    if (
      lead.ChannelId ||
      lead.ReasonId ||
      lead.SchoolingId ||
      lead.birthday ||
      lead.cep ||
      lead.car ||
      lead.city ||
      lead.district ||
      lead.finance ||
      lead.maritalStatus ||
      lead.motorcycle ||
      lead.sex ||
      lead.room ||
      lead.state ||
      lead.number ||
      lead.street
    )
      return true;

    return false;
  };

  const handleOpenModalEnterprise = (idVisit, Enterprise) => {
    setEnterpriseVisit(Enterprise || null);

    setSelectedIdVisit(idVisit);
    setIsModalEnterpriseOpen(true);
  };

  const handleOpenModalCorrector = (idVisit, corrector) => {
    setCorrectorVisit(corrector);

    setSelectedIdVisit(idVisit);
    setIsModalCorrectorOpen(true);
  };

  const handleOpenModalVisitDate = (idVisit, visitDate) => {
    let now = new Date(visitDate);
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

    setVisitDate(now.toISOString().slice(0, 16));
    setSelectedIdVisit(idVisit);
    setIsModalVisitDateOpen(true);
  };

  const handleFilter = (e) => {
    e.preventDefault();

    apiGetAllVisits(1, dayStartFilter, dayEndFilter);
    setPage(1);
  };

  useEffect(() => {
    apiGetAllVisits();
  }, []);

  return (
    <>
      <Header />

      <Container maxWidth="xl">
        <h1
          style={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "31px",
            color: "#000000",
          }}
        >
          Atendimento Visitas
        </h1>

        <form onSubmit={handleFilter}>
          <Box
            sx={{
              marginTop: "2em",
              marginBottom: { md: 0, xs: "1em" },
              justifyContent: "space-between",
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              alignItems: { md: "center" },
            }}
          >
            <TextTotalItems>
              Total de visita(s): <b>{totalItems}</b>
            </TextTotalItems>

            <Box
              sx={{ gap: "10px", display: "flex", alignItems: "flex-start" }}
            >
              <Input
                type="date"
                value={dayStartFilter}
                onChange={(e) => setDayStartFilter(e.target.value)}
                helperText="data inicio"
                size="small"
                sx={{ maxWidth: "200px" }}
              />

              <Input
                type="date"
                value={dayEndFilter}
                onChange={(e) => setDayEndFilter(e.target.value)}
                helperText="data final"
                size="small"
                sx={{ maxWidth: "200px" }}
              />

              <Button
                type="submit"
                sx={{ height: "40px", minWidth: "130px" }}
                icon={<SearchIcon />}
              >
                Buscar
              </Button>

              <Button
                sx={{
                  height: "40px",
                  filter: "grayscale(1)",
                  minWidth: "170px",
                }}
                onClick={() => handleResetFilter()}
                type="button"
                icon={<RestartAltIcon />}
              >
                Resetar Filtro
              </Button>
            </Box>
          </Box>
        </form>

        <Table>
          <thead>
            <tr>
              <th>Data atendimento</th>
              <th>Corretor</th>
              <th>Gerente</th>
              <th>Empresa</th>
              <th>Tipo visita</th>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Email</th>
              <th>Inserido por</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {visits?.length > 0 &&
              visits?.map((item) => (
                <tr key={item.id}>
                  <td>
                    {new Intl.DateTimeFormat("pt-BR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(item.createdAt))}
                  </td>
                  <td>{item?.Corrector?.name}</td>
                  <td>{item?.Corrector?.salesManager}</td>
                  <td>{item?.Corrector?.SalesCompany?.name}</td>
                  <td>{item?.VisitType?.title}</td>
                  <td>{item?.Lead?.name}</td>
                  <td>{item?.Lead?.phone}</td>
                  <td>{item?.Lead?.email}</td>
                  <td>{item?.byQrcode ? "Qr Code" : "Atendente"}</td>
                  <td style={{ padding: 0 }}>
                    <Tooltip
                      title={
                        verifyLeadAdditionalQuestionary(item.Lead)
                          ? "Atualizar questionário adicional"
                          : "Preencher questionário adicional"
                      }
                      disableInteractive
                    >
                      <IconButton
                        onClick={() => {
                          setLeadId(item.LeadId);
                          setOpenModalLead(true);
                          setSelectedLead(item.Lead);
                        }}
                      >
                        <EditIcon
                          fontSize="small"
                          sx={{
                            color: verifyLeadAdditionalQuestionary(item.Lead)
                              ? "#57c957"
                              : "#8f8e8e",
                          }}
                        />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title={
                        item.EnterpriseId
                          ? "Atualizar empreendimento"
                          : "Informar empreendimento"
                      }
                      disableInteractive
                    >
                      <IconButton
                        onClick={() => {
                          handleOpenModalEnterprise(item.id, item.Enterprise);
                        }}
                      >
                        <StoreIcon
                          fontSize="small"
                          sx={{
                            color: item.EnterpriseId ? "#57c957" : "#8f8e8e",
                          }}
                        />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title={
                        item.CorrectorId
                          ? "Atualizar corretor"
                          : "Informar corretor"
                      }
                      disableInteractive
                    >
                      <IconButton
                        onClick={() => {
                          handleOpenModalCorrector(item.id, {
                            id: item.CorrectorId,
                            name: item.Corrector,
                          });
                        }}
                      >
                        <HailIcon
                          fontSize="small"
                          sx={{
                            color: item.CorrectorId ? "#57c957" : "#8f8e8e",
                          }}
                        />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title={"Alterar data de atendimento"}
                      disableInteractive
                    >
                      <IconButton
                        onClick={() => {
                          handleOpenModalVisitDate(item.id, item.createdAt);
                        }}
                      >
                        <EventIcon
                          fontSize="small"
                          sx={{
                            color: "#57c957",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {visits?.length === 0 && loadingFull === false && (
          <div style={{ textAlign: "center" }}>
            <p>não foi encontrado nenhuma visita</p>
          </div>
        )}

        {visits?.length > 0 && (
          <div
            style={{
              marginBottom: "40px",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination count={lastPage} page={page} onChange={handleChange} />
          </div>
        )}
      </Container>

      <ModalLeadPlus
        openModal={openModalLead}
        setOpenModal={setOpenModalLead}
        leadId={leadId}
        apiGetAllVisits={apiGetAllVisits}
        lead={selectedLead}
      />

      <ModalEditDate
        open={isModalVisitDateOpen}
        setOpen={setIsModalVisitDateOpen}
        setLoadingFull={setLoadingFull}
        visitId={selectedIdVisit}
        visitDate={visitDate}
        apiGetAllVisits={apiGetAllVisits}
        setTypeSnackBar={setTypeSnackBar}
        setMessageSnackBar={setMessageSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />

      <ModalSelectEnterprise
        open={isModalEnterpriseOpen}
        setOpen={setIsModalEnterpriseOpen}
        setLoadingFull={setLoadingFull}
        visitId={selectedIdVisit}
        apiGetAllVisits={apiGetAllVisits}
        setTypeSnackBar={setTypeSnackBar}
        setMessageSnackBar={setMessageSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        enterpriseVisit={enterpriseVisit}
      />

      <ModalChangeCorrector
        open={isModalCorrectorOpen}
        setOpen={setIsModalCorrectorOpen}
        setLoadingFull={setLoadingFull}
        visitId={selectedIdVisit}
        apiGetAllVisits={apiGetAllVisits}
        setTypeSnackBar={setTypeSnackBar}
        setMessageSnackBar={setMessageSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        correctorVisit={correctorVisit}
      />

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />
    </>
  );
}
