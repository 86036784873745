import { useEffect, useState } from "react";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
import { Box, MenuItem, Pagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import NumberFormat from "react-number-format";

import Header from "../../components/Header";
import Button from "../../components/Button";
import SnackBar from "../../components/SnackBar";
import LoadingFull from "../../components/LoadingFull";
import api from "../../services/api";
import Input from "../../components/Input";
import Select from "../../components/Select";
import FloatingButton from "../../components/FloatingButton";
import Table from "../../components/Table";
import { TextTotalItems } from "./styled";

export default function Visits() {
  const userData = useSelector((state) => state.users);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const [loadingFull, setLoadingFull] = useState(false);

  const [visits, setVisits] = useState([]);
  const [lastPage, setLastPage] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [stores, setStores] = useState([]);

  const [storeFilter, setStoreFilter] = useState("");
  const [dayStartFilter, setDayStartFilter] = useState("");
  const [dayEndFilter, setDayEndFilter] = useState("");

  const apiGetAllStores = async () => {
    setLoadingFull(true);

    await api
      .get("/store", { headers: { Authorization: `Bearer ${userData.token}` } })
      .then((response) => {
        setStores(response.data);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const apiGetAllVisits = async (
    page = 1,
    storeFilter = "",
    dayStartFilter = "",
    dayEndFilter = ""
  ) => {
    setLoadingFull(true);

    await api
      .get(
        `/visit?page=${page}
            ${storeFilter && "&store=" + storeFilter}
            ${dayStartFilter && "&daystart=" + dayStartFilter}
            ${dayEndFilter && "&dayend=" + dayEndFilter}
        `,
        { headers: { Authorization: `Bearer ${userData.token}` } }
      )
      .then((response) => {
        setLastPage(response.data.totalPages);
        setTotalItems(response.data.totalItems);
        setVisits(response.data.visits);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const handleChange = (event, value) => {
    apiGetAllVisits(value, storeFilter, dayStartFilter, dayEndFilter);
    setPage(value);
  };

  const handleResetFilter = () => {
    setStoreFilter("");
    setDayStartFilter("");
    setDayEndFilter("");
    apiGetAllVisits(page);
  };

  const handleFilter = (e) => {
    e.preventDefault();

    setPage(1);
    apiGetAllVisits(1, storeFilter, dayStartFilter, dayEndFilter);
  };

  useEffect(() => {
    apiGetAllVisits();
    apiGetAllStores();
  }, []);

  return (
    <>
      <Header />

      <Container maxWidth="xl">
        <h1
          style={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "31px",
            color: "#000000",
          }}
        >
          Visitas
        </h1>

        <form onSubmit={handleFilter}>
          <Box
            sx={{
              marginTop: "2em",
              marginBottom: { md: 0, xs: "1em" },
              justifyContent: "space-between",
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              alignItems: { md: "center" },
            }}
          >
            <TextTotalItems>
              Total de visita(s): <b>{totalItems}</b>
            </TextTotalItems>

            <Box
              sx={{ gap: "10px", display: "flex", alignItems: "flex-start" }}
            >
              <Input
                type="date"
                value={dayStartFilter}
                onChange={(e) => setDayStartFilter(e.target.value)}
                helperText="data inicio"
                size="small"
                sx={{ maxWidth: "200px" }}
              />

              <Input
                type="date"
                value={dayEndFilter}
                onChange={(e) => setDayEndFilter(e.target.value)}
                helperText="data final"
                size="small"
                sx={{ maxWidth: "200px" }}
              />

              <Select
                sx={{ maxWidth: "200px" }}
                value={storeFilter}
                onChange={(e) => setStoreFilter(e.target.value)}
                size="small"
                displayEmpty
              >
                <MenuItem value={""}>
                  <em>Todas as lojas</em>
                </MenuItem>
                {stores?.length > 0 &&
                  stores?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>

              <Button
                sx={{ height: "40px", minWidth: "130px!important" }}
                type="submit"
                icon={<SearchIcon />}
              >
                Buscar
              </Button>

              <Button
                sx={{
                  height: "40px",
                  filter: "grayscale(1)",
                  minWidth: "170px!important",
                }}
                onClick={() => handleResetFilter()}
                icon={<RestartAltIcon />}
                type="button"
              >
                Resetar Filtro
              </Button>
            </Box>
          </Box>
        </form>

        <Table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Tipo visita</th>
              <th>Corretor</th>
              <th>Loja</th>
              <th>Inserido por</th>
              <th>Data atendimento</th>
            </tr>
          </thead>
          <tbody>
            {visits?.length > 0 &&
              visits?.map((item) => (
                <tr key={item.id}>
                  <td>{item?.Lead?.name}</td>
                  <td>{item?.Lead?.email}</td>
                  <td>
                    <NumberFormat
                      value={item?.Lead?.phone}
                      className="foo"
                      displayType={"text"}
                      format={
                        item?.Lead?.phone?.length === 11
                          ? "(##) #####-####"
                          : "(##) ####-####"
                      }
                      renderText={(value, props) => value}
                    />
                  </td>
                  <td>{item?.VisitType?.title}</td>
                  <td>{item?.Corrector?.name}</td>
                  <td>{item?.Store?.name}</td>
                  <td>{item?.byQrcode ? "QR Code" : "Atendente"}</td>
                  <td>
                    {new Intl.DateTimeFormat("pt-BR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(item?.createdAt))}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {visits?.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <p>não foi encontrado nenhuma visita</p>
          </div>
        )}

        {visits?.length > 0 && (
          <div
            style={{
              marginTop: "20px",
              marginBottom: "40px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination count={lastPage} page={page} onChange={handleChange} />
          </div>
        )}
      </Container>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />

      <FloatingButton>Nova Visita</FloatingButton>
    </>
  );
}
