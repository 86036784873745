import styled from "styled-components";

export const Area = styled.main`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  h1 {
    font-weight: 700;
    font-size: 26px;
    line-height: 1.5em;
    color: #000000;
    margin: 0;
  }

  h6 {
    padding-top: 0.5em;
    padding-bottom: 1em;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5em;
    color: #505050;
  }

  span {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--main-color);
    text-decoration: underline;
    text-underline-offset: 2px;

    :hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 920px) {
    flex-direction: column;

    h1 {
      padding-top: 1em;
    }

    span {
      font-size: 13px;
    }
  }
`;

export const BackgroundImage = styled.div`
  flex: 1;
  width: 100%;
  background-image: url("/assets/images/agradecemosSuaVisita.png");
  background-size: cover;

  div {
    display: flex;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1100px) and (min-width: 920px) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media (max-width: 920px) and (min-width: 468px) {
    flex: 0.3;
    background-position: center;

    img {
      width: 220px;
      height: auto;
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }

  @media (max-width: 468px) {
    flex: 0.5;
    background-position: center;

    img {
      width: 140px;
      height: auto;
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
`;

export const LoginArea = styled.div`
  flex: 1;
  align-self: center;
`;

export const ContentLogin = styled.div`
  padding-top: 5em;
  padding-bottom: 3em;
  width: 100%;
  padding-right: 15%;
  padding-left: 15%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  max-height: ${window.innerHeight}px;

  @media (max-width: 1100px) and (min-width: 920px) {
    padding-right: 7%;
    padding-left: 7%;
  }

  @media (max-width: 920px) and (min-width: 468px) {
    background: white;
    padding: 2em;
    width: 100%;
    border-radius: 12px;
    max-height: ${window.innerHeight - window.innerHeight * 0.2}px;
  }

  @media (max-width: 468px) {
    background: white;
    padding: 1em;
    width: 100%;
    max-height: ${window.innerHeight - 200}px;
  }
`;

export const Card = styled.div`
  margin-bottom: 1em;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 1em;

  h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #000000;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0.25em;
  }

  p {
    padding-bottom: 0.25em;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #505050;
    margin: 0;
  }

  @media (max-width: 920px) and (min-width: 468px) {
    width: 100%;
  }
`;

export const CardSelecao = styled.div`
  margin-left: 0.5em;
  background: ${(props) => (props.active ? "var(--main-color)" : "#EBADAE")};
  opacity: ${(props) => (props.disabled ? ".3" : "1")};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 0.8em;
  cursor: pointer;
  transition: all 0.1s ease-out;

  :hover {
    opacity: 0.6;
  }

  svg {
    font-size: 20px;
    color: white;
  }

  div {
    width: 20px;
    background: white;
    height: 20px;
    border-radius: 50px;
  }

  span {
    text-decoration: none;
    align-self: center;
    margin-left: 15%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => (props.active ? "#FFFFFF" : "#000000")};
  }
`;

export const RegisterTitle = styled.h3`
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5em;
  margin: 0;
  padding-top: 1em;
  padding-bottom: 0.5em;
`;

export const RegisterDescription = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;
  color: #878787;
  margin: 0;
`;
