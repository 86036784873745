import styled from "styled-components";

export const Title = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  line-height: 24px;
  color: #000000;
  margin: 0;
  padding-bottom: 0.3em;
`;

export const Show = styled.div`
  margin: 0 0.5em;

  @media (max-width: 899px) {
    margin: 0;

    display: ${(props) => (props.active ? "block" : "none")};
  }
`;

export const InfoStatus = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  top: 1.5em;
  color: var(--main-color);

  span {
    align-self: flex-end;
  }

  svg {
    margin-left: 0.5em;
    font-size: 18px;
  }
`;
