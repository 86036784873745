import styled from "styled-components";

export const Area = styled.div`
  flex-direction: column;
  display: flex;
`;

export const Card = styled.div`
  margin-top: 1em;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 1em;
  width: 100%;

  h6 {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;

    span {
      font-size: 13px;
      font-weight: 400;
      color: grey;
    }
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #505050;
  }
`;

export const CardSelecao = styled.div`
  background: ${(props) => (props.active ? "var(--main-color)" : "#EBADAE")};
  opacity: ${(props) => (props.disabled ? ".3" : "1")};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 0.8em;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  transition: all 0.1s ease-out;

  :hover {
    opacity: ${(props) => (props.disabled ? ".3" : ".6")};
  }

  svg {
    font-size: 20px;
    color: white;
  }

  div {
    width: 20px;
    background: white;
    height: 20px;
    border-radius: 50px;
  }

  span {
    align-self: center;
    margin-left: 15%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => (props.active ? "#FFFFFF" : "#000000")};
  }
`;

export const Badge = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  background: var(--main-color);
  align-self: center;
  text-align: center;
  border-radius: 50px;
  padding: 0.3em 0.6em;
  color: #ffffff;
`;
