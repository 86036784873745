import { Box } from "@mui/material";

import { TableCustom } from "./styled";

export default function Table({ children, isTableHeaderFixed = false }) {
  return (
    <Box
      sx={{ overflow: isTableHeaderFixed ? "unset" : "auto", width: "100%" }}
    >
      <TableCustom>{children}</TableCustom>
    </Box>
  );
}
