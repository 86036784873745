import { useEffect, useState } from "react";
import { Container } from "@mui/system";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { Link, useLocation } from "react-router-dom";
import StoreIcon from "@mui/icons-material/Store";
import CoffeeIcon from "@mui/icons-material/Coffee";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";

import {
  LogoArea,
  ProfileArea,
  HeaderContent,
  DialogArea,
  DialogButtons,
} from "./styled";
import { deslogar, logar } from "../../store/modules/users/actions";
import LoadingFull from "../LoadingFull";
import api from "../../services/api";
import SnackBar from "../SnackBar";
import Dialog from "../Dialog";
import ModalRoulette from "./ModalRoulette";

export default function Header() {
  const dispatch = useDispatch();

  const [openModalRoulette, setOpenModalRoulette] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingFull, setLoadingFull] = useState(false);
  const userData = useSelector((state) => state.users);
  const netStatus = useSelector((state) => state.netStatus);
  const [openDialogModeAway, setOpenDialogModeAway] = useState(false);
  const location = useLocation();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const sendLeadAndVisitInStorage = async () => {
    setLoadingFull(true);

    const arrayLeadOffline = JSON.parse(localStorage.getItem("leads"));
    const arrayVisitOffline = JSON.parse(localStorage.getItem("visits"));

    for (let i = 0; i < arrayLeadOffline.length; i++) {
      let leadId = null;

      if (arrayLeadOffline[i].uuid) {
        leadId = await apiHandleLeadReturnId(arrayLeadOffline[i]);
      }

      if (leadId) {
        let visit = arrayVisitOffline.find((item) => {
          return item.visit?.uuid === arrayLeadOffline[i].uuid;
        });

        if (visit) {
          apiHandleVisit(leadId, visit);
        }
      }
    }

    setLoadingFull(false);
  };

  const apiHandleLeadReturnId = async (newLead) => {
    const insertNewLead = {
      name: newLead?.name?.trim(),
      email: newLead?.email?.trim(),
      phone: newLead?.phone
        ?.replaceAll(/[\(\)\.\s-]+/g, "")
        .replaceAll(" ", ""),
      mediaId: newLead?.mediaId,
      cia: newLead?.cia,
    };

    return await api
      .post("/lead", insertNewLead, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        const arrayLeads = JSON.parse(localStorage.getItem("leads"));

        const newArrayLeads = arrayLeads.filter((item) => {
          return item.uuid !== newLead.uuid;
        });

        localStorage.setItem("leads", JSON.stringify(newArrayLeads));

        return response.data.id;
      })
      .catch((err) => {
        console.log(err.response.data);
        return false;
      });
  };

  const apiHandleVisit = async (leadId, visit) => {
    const newVisit = {
      visit: {
        CorrectorId: visit.visit?.CorrectorId,
        VisitTypeId: visit.visit?.VisitTypeId,
        day: visit.visit?.day,
        LeadId: leadId,
      },
      rouletteId: visit.rouletteId,
    };

    await api
      .post("/visit", newVisit, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        const arrayVisits = JSON.parse(localStorage.getItem("visits"));

        const newArrayVisits = arrayVisits.filter((item) => {
          return item.visit.uuid !== visit.visit.uuid;
        });

        localStorage.setItem("visits", JSON.stringify(newArrayVisits));
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");

    dispatch(deslogar());
  };

  const activeModeAway = async () => {
    setLoadingFull(true);
    setOpenDialogModeAway(false);

    await api
      .put(
        "/user/awaymode/active",
        {},
        { headers: { Authorization: `Bearer ${userData.token}` } }
      )
      .then((response) => {
        const newUserData = {
          ...userData,
          awayMode: true,
        };

        localStorage.setItem("user", JSON.stringify(newUserData));

        dispatch(logar(newUserData));
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  useEffect(() => {
    if (netStatus) {
      if (localStorage.getItem("leads") !== null) {
        JSON.parse(localStorage.getItem("leads")).length > 0 &&
          sendLeadAndVisitInStorage();
      }
    }
  }, [netStatus]);

  return (
    <>
      <Container maxWidth="xl">
        <HeaderContent>
          <Box
            sx={{
              display: "flex",
              gap: { md: "48px", xs: "16px" },
              alignItems: "center",
            }}
          >
            <LogoArea>
              <Link to="/home">
                <img
                  src="../assets/images/logo_planoplano.svg"
                  alt="Logo Plano&Plano"
                  width={200}
                  height={47}
                />
              </Link>
            </LogoArea>

            <Link to="/campanha-2024">
              <Typography
                sx={{
                  fontSize: { md: "14px", xs: "12px" },
                  color: "#000",
                  fontWeight: "500",
                  textDecoration: "underline",
                  "&:hover": {
                    opacity: ".8",
                  },
                }}
              >
                Campanha Apê Grátis O Jogo
              </Typography>
            </Link>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ProfileArea
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <p>Olá, {userData.name}</p>

              <ArrowDropDownIcon />
            </ProfileArea>

            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <Link to="/home">
                <MenuItem
                  sx={{
                    background:
                      location.pathname === "/home" ? "#c1c1c150" : "white",
                  }}
                  style={{ display: "flex" }}
                >
                  <AccountCircleOutlinedIcon style={{ fontSize: 20 }} />
                  <span style={{ paddingLeft: ".8em", paddingRight: ".2em" }}>
                    Nova visita
                  </span>
                </MenuItem>
              </Link>

              <MenuItem
                onClick={() => {
                  setOpenModalRoulette(true);
                  handleClose();
                }}
                style={{ display: "flex" }}
              >
                <SensorOccupiedIcon style={{ fontSize: 20 }} />

                <span style={{ paddingLeft: ".8em", paddingRight: ".2em" }}>
                  Visualizar roleta
                </span>
              </MenuItem>

              {userData?.admin && (
                <div>
                  <Link to="/visitas">
                    <MenuItem
                      sx={{
                        background:
                          location.pathname === "/visitas"
                            ? "#c1c1c1"
                            : "white",
                      }}
                      style={{ display: "flex" }}
                    >
                      <GroupOutlinedIcon style={{ fontSize: 20 }} />
                      <span
                        style={{ paddingLeft: ".8em", paddingRight: ".2em" }}
                      >
                        Visitas
                      </span>
                    </MenuItem>
                  </Link>

                  <Link to="/lojas">
                    <MenuItem
                      sx={{
                        background:
                          location.pathname === "/lojas" ? "#c1c1c1" : "white",
                      }}
                      style={{ display: "flex" }}
                    >
                      <StoreIcon style={{ fontSize: 20 }} />
                      <span
                        style={{ paddingLeft: ".8em", paddingRight: ".2em" }}
                      >
                        Lojas
                      </span>
                    </MenuItem>
                  </Link>

                  <Link to="/usuarios">
                    <MenuItem
                      sx={{
                        background:
                          location.pathname === "/usuarios"
                            ? "#c1c1c1"
                            : "white",
                      }}
                      style={{ display: "flex" }}
                    >
                      <AdminPanelSettingsOutlinedIcon
                        style={{ fontSize: 20 }}
                      />
                      <span
                        style={{ paddingLeft: ".8em", paddingRight: ".2em" }}
                      >
                        Usuários
                      </span>
                    </MenuItem>
                  </Link>
                </div>
              )}

              <Link to="/atendimento-visitas">
                <MenuItem
                  sx={{
                    background:
                      location.pathname === "/atendimento-visitas"
                        ? "#c1c1c1"
                        : "white",
                  }}
                  style={{ display: "flex" }}
                >
                  <GroupOutlinedIcon style={{ fontSize: 20 }} />
                  <span style={{ paddingLeft: ".8em", paddingRight: ".2em" }}>
                    Atendimento visitas
                  </span>
                </MenuItem>
              </Link>

              <Link to="/visitas-modo-ausente">
                <MenuItem
                  sx={{
                    background:
                      location.pathname === "/visitas-modo-ausente"
                        ? "#c1c1c1"
                        : "white",
                  }}
                  style={{ display: "flex" }}
                >
                  <GroupOutlinedIcon style={{ fontSize: 20 }} />
                  <span style={{ paddingLeft: ".8em", paddingRight: ".2em" }}>
                    Visitas modo ausente
                  </span>
                </MenuItem>
              </Link>

              <MenuItem
                onClick={() => {
                  setOpenDialogModeAway(true);
                  handleClose();
                }}
                sx={{
                  background:
                    location.pathname === "/modo-ausente" ? "#c1c1c1" : "white",
                }}
                style={{ display: "flex" }}
              >
                <CoffeeIcon style={{ fontSize: 20 }} />
                <span style={{ paddingLeft: ".8em", paddingRight: ".2em" }}>
                  Modo ausente
                </span>
              </MenuItem>

              <MenuItem
                style={{ display: "flex" }}
                onClick={() => {
                  setAnchorEl(null);
                  handleLogout();
                }}
              >
                <LogoutIcon style={{ fontSize: 20 }} />
                <span style={{ paddingLeft: ".8em", paddingRight: ".2em" }}>
                  Sair
                </span>
              </MenuItem>
            </Menu>
          </Box>
        </HeaderContent>
      </Container>

      <Dialog
        width={450}
        open={openDialogModeAway}
        setOpen={setOpenDialogModeAway}
      >
        <DialogArea>
          <h1>Confirmação</h1>
          <p>Deseja ativar o modo ausente?</p>

          <DialogButtons>
            <button onClick={() => activeModeAway()}>Sim</button>

            <button onClick={() => setOpenDialogModeAway(false)}>
              Cancelar
            </button>
          </DialogButtons>
        </DialogArea>
      </Dialog>

      <LoadingFull open={loadingFull} />

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <ModalRoulette open={openModalRoulette} setOpen={setOpenModalRoulette} />
    </>
  );
}
