import { useEffect, useState } from "react";
import {
  Divider,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import uuid from "react-uuid";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import Select from "../../../../components/Select";
import SnackBar from "../../../../components/SnackBar";
import LoadingFull from "../../../../components/LoadingFull";
import { Card } from "./styled";
import api from "../../../../services/api";
import { storageLead } from "../../../../store/modules/leads/actions";
import { cleanVisit } from "../../../../store/modules/visits/actions";
import { inputHandlePhone } from "../../../../utils/masksInputs";

export default function StepOne({ step, setStep }) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({});
  const userData = useSelector((state) => state.users);
  const netStatus = useSelector((state) => state.netStatus);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const [loadingFull, setLoadingFull] = useState(false);

  const [isCia, setIsCia] = useState(false);

  const [medias, setMedias] = useState([]);

  const submitForm = async (newLead) => {
    setLoadingFull(true);

    const insertNewLead = {
      name: newLead?.name?.trim(),
      email: newLead?.email?.trim(),
      phone: newLead?.phone
        ?.replaceAll(/[\(\)\.\s-]+/g, "")
        .replaceAll(" ", ""),
      mediaId: newLead?.mediaId,
      cia: newLead?.cia,
    };

    await api
      .post("/lead", insertNewLead, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        const leadInfo = {
          isNew: response.data.isNew,
          id: response.data.id,
          isCia: isCia,
        };

        dispatch(storageLead(leadInfo));
        dispatch(cleanVisit());

        setTypeSnackBar("success");
        setMessageSnackBar(
          response.data.isNew
            ? "Lead criado com sucesso!"
            : "Lead já existente!"
        );
        setOpenSnackBar(true);

        setStep(2);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const storageLeadOffline = (newLead) => {
    const list = localStorage.getItem("leads");
    let newList = [newLead];

    if (list) {
      newList = [...JSON.parse(list), newLead];
    }

    dispatch(
      storageLead({
        selectedSalesCompany: newLead.SalesCompanyId,
        uuid: newLead.uuid,
      })
    );

    localStorage.setItem("leads", JSON.stringify(newList));
    setStep(2);
  };

  const getAllMedias = async () => {
    await api
      .get("media", { headers: { Authorization: `Bearer ${userData.token}` } })
      .then((response) => {
        setMedias(response.data);

        localStorage.setItem("media", JSON.stringify(response.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const verifyNewStatusForSubmit = (data) => {
    const newLead = {
      uuid: uuid(),
      name: data.nome.trim(),
      email: data.email.trim(),
      phone: data.telefone.replaceAll(/[\(\)\.\s-]+/g, "").replaceAll(" ", ""),
      mediaId: data.media,
      cia: isCia,
    };

    if (netStatus) {
      submitForm(newLead);
    } else {
      storageLeadOffline(newLead);
    }
  };

  const verifyNetStatusForContents = (item, functionSet, functionApi) => {
    if (netStatus) {
      functionApi();
    } else {
      const list = localStorage.getItem(item);
      functionSet(JSON.parse(list));
    }
  };

  const resetInputs = () => {
    setValue("email", "");
    setValue("telefone", "");
    setValue("media", "");
  };

  useEffect(() => {
    verifyNetStatusForContents("media", setMedias, getAllMedias);
  }, []);

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit(verifyNewStatusForSubmit)}>
          <p style={{ margin: 0 }}>
            Preencha os dados do lead para prosseguir com a visita
          </p>

          <Divider sx={{ pt: 1, mb: 2 }} />

          <p
            style={{
              margin: 0,
              paddingBottom: 4,
              fontWeight: "500",
              fontSize: 14,
            }}
          >
            Selecione se é CIA
          </p>
          <FormControlLabel
            control={
              <Switch
                checked={isCia}
                disabled={step > 1}
                onChange={(e) => {
                  setIsCia(e.target.checked);
                  resetInputs();
                }}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "var(--main-color)",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "var(--main-color)",
                  },
                }}
              />
            }
            label={isCia ? "CIA" : "Não"}
          />

          <Divider sx={{ pt: 1 }} />

          <Label>Nome</Label>

          <Controller
            name="nome"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field, ref }) => (
              <Input
                {...field}
                disabled={step > 1}
                error={!!errors.nome}
                ref={ref}
                placeholder="Nome completo do cliente"
              />
            )}
          />

          {!isCia && (
            <>
              <Label>E-mail</Label>

              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: false,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Email inválido",
                  },
                }}
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    disabled={step > 1}
                    error={!!errors.email}
                    ref={ref}
                    placeholder="E-mail para contato"
                  />
                )}
              />

              <Label>Telefone com DDD</Label>

              <Controller
                name="telefone"
                control={control}
                rules={{
                  required: watch("telefone") ? true : false,
                  minLength: {
                    value: 14,
                    message: "Número de telefone inválido",
                  },
                }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur } }) => (
                  <Input
                    value={value}
                    onChange={(e) => inputHandlePhone(e, onChange)}
                    onBlur={onBlur}
                    disabled={step > 1}
                    error={!!errors.telefone}
                    InputProps={{
                      inputMode: "numeric",
                    }}
                    placeholder="Telefone para contato"
                  />
                )}
              />

              {errors?.telefone && (
                <Typography sx={{ fontSize: "12px", color: "red" }}>
                  {errors?.telefone?.message}
                </Typography>
              )}

              <Label>Mídia declarada</Label>

              <Controller
                name="media"
                control={control}
                rules={{ required: false }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Select
                    {...field}
                    disabled={step > 1}
                    displayEmpty
                    error={!!errors.media}
                    ref={ref}
                  >
                    <MenuItem value={""}>
                      <em>Selecione a mídia declarada</em>
                    </MenuItem>
                    {medias?.length > 0 &&
                      medias?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </>
          )}

          {step === 1 && (
            <Button type="submit" sx={{ mt: 2 }} icon={<ArrowRightAltIcon />}>
              Continuar para corretor
            </Button>
          )}
        </form>
      </Card>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />
    </>
  );
}
