import { useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/system";
import SignalCellularNodataIcon from "@mui/icons-material/SignalCellularNodata";
import { Link, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import { Title, Show, InfoStatus } from "./styled";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";
import StepFour from "./components/StepFour";
import { changeStatus } from "../../store/modules/netStatus/actions";
import SnackBar from "../../components/SnackBar";

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const netStatus = useSelector((state) => state.netStatus);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success | info

  useEffect(() => {
    const userDataLocalStorage = localStorage.getItem("user");
    const parseUserDataLocalStorage = JSON.parse(userDataLocalStorage);

    if (parseUserDataLocalStorage.redirect) {
      const resetUserDataRedirect = {
        ...parseUserDataLocalStorage,
        redirect: "",
      };
      localStorage.setItem("user", JSON.stringify(resetUserDataRedirect));

      navigate(parseUserDataLocalStorage.redirect);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("visitsAwayMode")) {
      if (JSON.parse(localStorage.getItem("visitsAwayMode")).length > 0) {
        setOpenSnackBar(true);
        setTypeSnackBar("info");
        setMessageSnackBar(
          <Link to="/visitas-modo-ausente">
            Possui visitas que ainda não foram aprovadas. Clique aqui para ver
          </Link>
        );
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("offline", () => {
      dispatch(changeStatus(false));
    });
    window.addEventListener("online", () => {
      dispatch(changeStatus(true));
    });

    return () => {
      window.removeEventListener("offline", () => {
        dispatch(changeStatus(false));
      });
      window.removeEventListener("online", () => {
        dispatch(changeStatus(true));
      });
    };
  }, []);

  return (
    <>
      {!netStatus && (
        <InfoStatus>
          <span>Sem conexão</span>
          <SignalCellularNodataIcon />
        </InfoStatus>
      )}

      <Header />

      <Container maxWidth="xl">
        <h1
          style={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "31px",
            color: "#000000",
          }}
        >
          Nova Visita
        </h1>

        <Grid container mb={5}>
          <Grid item md={3} xs={12}>
            <Show active={step === 1}>
              <Title>Dados do lead</Title>
              <Divider
                sx={{ borderBottomWidth: 2, borderColor: "var(--main-color)" }}
              />

              <StepOne step={step} setStep={setStep} />
            </Show>
          </Grid>
          <Grid item md={3} xs={12}>
            <Show active={step === 2}>
              <Title>Tipo de visita</Title>

              <Divider
                sx={{
                  borderBottomWidth: 2,
                  borderColor: step > 1 ? "var(--main-color)" : "transparent",
                }}
              />

              {step > 1 && <StepTwo step={step} setStep={setStep} />}
            </Show>
          </Grid>
          <Grid item md={3} xs={12}>
            <Show active={step === 3}>
              <Title>Corretor da vez</Title>
              <Divider
                sx={{
                  borderBottomWidth: 2,
                  borderColor: step > 2 ? "var(--main-color)" : "transparent",
                }}
              />

              {step > 2 && <StepThree step={step} setStep={setStep} />}
            </Show>
          </Grid>
          <Grid item md={3} xs={12}>
            <Show active={step === 4 || step === 5}>
              <Title>Cadastro completo</Title>
              <Divider
                sx={{
                  borderBottomWidth: 2,
                  borderColor: step > 3 ? "var(--main-color)" : "transparent",
                }}
              />

              {step > 3 && <StepFour step={step} setStep={setStep} />}
            </Show>
          </Grid>
        </Grid>
      </Container>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
        infinite={true}
      />
    </>
  );
}
