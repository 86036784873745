import styled from "styled-components";

export const Card = styled.div`
  margin-top: 1em;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 1em;
  width: 100%;

  p:first-child {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5em;
    color: #505050;
    margin: 0;
    padding-bottom: 0.5em;
  }
`;
