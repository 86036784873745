import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Login from "./pages/Login";
import Home from "./pages/Home";
import { logar } from "./store/modules/users/actions";
import RecoveryPassword from "./pages/RecoveryPassword";
import RecoverNewPassword from "./pages/RecoverNewPassword";
import Users from "./pages/Users";
import Visits from "./pages/Visits";
import AttendanceVisits from "./pages/AttendanceVisits";
import AwayMode from "./pages/AwayMode";
import VisitsAwayMode from "./pages/VisitsAwayMode";
import Error from "./pages/Error";
import ClientPublic from "./pages/ClientPublic";
import Stores from "./pages/Stores";
import Cpm24 from "./pages/Cpm24";

export default function App() {
  const dispatch = useDispatch();

  if (localStorage.getItem("user")) {
    dispatch(logar(JSON.parse(localStorage.getItem("user"))));
  }

  return (
    <Routes>
      <Route path="*" element={<Error />} />

      <Route
        path="/"
        element={
          <LoginRedirect>
            <Login />
          </LoginRedirect>
        }
      />

      <Route path="/cliente/loja/:storeSlug" element={<ClientPublic />} />

      <Route
        path="/recuperar-senha"
        element={
          <LoginRedirect>
            <RecoveryPassword />
          </LoginRedirect>
        }
      />

      <Route
        path="/nova-senha"
        element={
          <LoginRedirect>
            <RecoverNewPassword />
          </LoginRedirect>
        }
      />

      <Route
        path="/home"
        element={
          <RequireAuthWithoutAwayMode>
            <Home />
          </RequireAuthWithoutAwayMode>
        }
      />

      <Route
        path="/campanha-2024"
        element={
          <RequireAuthWithoutAwayMode>
            <Cpm24 />
          </RequireAuthWithoutAwayMode>
        }
      />

      <Route
        path="/usuarios"
        element={
          <RequireAuthAdmin>
            <Users />
          </RequireAuthAdmin>
        }
      />

      <Route
        path="/visitas"
        element={
          <RequireAuthAdmin>
            <Visits />
          </RequireAuthAdmin>
        }
      />

      <Route
        path="/atendimento-visitas"
        element={
          <RequireAuthWithoutAwayMode>
            <AttendanceVisits />
          </RequireAuthWithoutAwayMode>
        }
      />

      <Route
        path="/lojas"
        element={
          <RequireAuthAdmin>
            <Stores />
          </RequireAuthAdmin>
        }
      />

      <Route
        path="/modo-ausente"
        element={
          <RequireAuthWithAwayMode>
            <AwayMode />
          </RequireAuthWithAwayMode>
        }
      />

      <Route
        path="/visitas-modo-ausente"
        element={
          <RequireAuthWithoutAwayMode>
            <VisitsAwayMode />
          </RequireAuthWithoutAwayMode>
        }
      />
    </Routes>
  );
}

function LoginRedirect({ children }) {
  const user = useSelector((state) => state.users);

  if (!user) return children;

  return <Navigate to="/home" replace />;
}

function RequireAuth({ children }) {
  const user = useSelector((state) => state.users);

  if (user) return children;

  return <Navigate to="/" replace />;
}

function RequireAuthWithoutAwayMode({ children }) {
  const user = useSelector((state) => state.users);

  if (user && !user.awayMode) return children;

  if (user && user.awayMode) return <Navigate to="/modo-ausente" replace />;

  return <Navigate to="/" replace />;
}

function RequireAuthWithAwayMode({ children }) {
  const user = useSelector((state) => state.users);

  if (user && user.awayMode) return children;

  if (user && !user.awayMode) return <Navigate to="/home" replace />;

  return <Navigate to="/" replace />;
}

function RequireAuthAdmin({ children }) {
  const user = useSelector((state) => state.users);

  if (user && user.admin) return children;

  return <Navigate to="/" replace />;
}
