import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

export const Title = styled.h2`
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  margin: 0;
  color: #000000;
  padding-bottom: 0.5em;
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: #505050;
`;

export const CardSelect = styled.div`
  background: ${(props) => (props.active ? "var(--main-color)" : "#EBADAE")};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.8em;
  cursor: pointer;
  transition: all 0.1s ease-out;

  :hover {
    opacity: 0.6;
  }

  svg {
    font-size: 20px;
    color: white;
  }

  div {
    width: 20px;
    background: white;
    height: 20px;
    border-radius: 50px;
  }

  span {
    width: max-content;
    align-self: center;
    margin-left: 15%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => (props.active ? "#FFFFFF" : "#000000")};
  }
`;

export const CloseButton = styled(CloseIcon)`
  font-size: 28px !important;
  padding: 2px;
  cursor: pointer;

  :hover {
    background: lightgrey;
    border-radius: 50px;
  }
`;

export const AreaCardSelect = styled.div`
  display: flex;
  gap: 20px;
  position: relative;
  z-index: 2;

  @media (max-width: 920px) and (min-width: 468px) {
    gap: 5px;
  }

  @media (max-width: 468px) {
    flex-direction: column;
    gap: 3px;
  }
`;
