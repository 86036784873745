export default function leads(state = null, action) {
  switch (action.type) {
    case "STORAGE":
      return action.leads;

    case "CLEAN":
      return null;

    default:
      return state;
  }
}
