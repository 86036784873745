import { ButtonCustom } from "./styled";

export default function Button({
  onClick,
  type,
  style,
  sx,
  icon,
  children,
  invertIcon,
  disabled,
}) {
  return (
    <ButtonCustom
      type={type}
      onClick={onClick}
      style={style}
      sx={sx}
      variant="contained"
      disabled={disabled}
    >
      {invertIcon ? (
        <>
          {icon}
          {children}
        </>
      ) : (
        <>
          {children}
          {icon}
        </>
      )}
    </ButtonCustom>
  );
}
