export default function visits(state = null, action) {
  switch (action.type) {
    case "CREATE":
      return action.visits;

    case "CLEANER":
      return null;

    default:
      return state;
  }
}
