import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { logar } from "../../store/modules/users/actions";
import { Area, BackgroundImage, LoginArea, ContentLogin } from "./styled";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Label from "../../components/Label";
import api from "../../services/api";
import SnackBar from "../../components/SnackBar";
import LoadingFull from "../../components/LoadingFull";

export default function Login() {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({});

  const navigate = useNavigate();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const [loadingFull, setLoadingFull] = useState(false);

  const handleLogin = async (data) => {
    setLoadingFull(true);

    await api
      .post("/user/login", { email: data.email, password: data.password })
      .then((response) => {
        api
          .get("/me", {
            headers: { Authorization: `Bearer ${response.data.token}` },
          })
          .then((meResponse) => {
            reset();

            const userData = {
              name: meResponse.data.name,
              id: meResponse.data.id,
              token: response.data.token,
              admin: response.data.admin,
              awayMode: response.data.awayMode,
              email: meResponse.data.email,
              redirect: "",
            };

            localStorage.setItem("user", JSON.stringify(userData));

            localStorage.setItem("netStatus", true);

            dispatch(logar(userData));
          })
          .catch((err) => {
            setTypeSnackBar("error");
            setMessageSnackBar(err.response?.data?.message);
            setOpenSnackBar(true);
          });
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  return (
    <>
      <Area>
        <BackgroundImage />
        <LoginArea style={{ flex: 1 }}>
          <form onSubmit={handleSubmit(handleLogin)}>
            <ContentLogin>
              <h1>Bem vindo de volta!</h1>
              <h6>
                Acesse o sistema de visitas Plano&Plano para gerenciar visitas
                de loja
              </h6>

              <Label>Email</Label>

              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    error={!!errors.email}
                    ref={ref}
                    inputProps={{
                      inputMode: "email",
                    }}
                    placeholder="Digite o seu email"
                  />
                )}
              />

              <Label style={{ marginTop: "1em" }}>Senha</Label>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    error={!!errors.password}
                    ref={ref}
                    placeholder="Digite a sua senha"
                    type="password"
                  />
                )}
              />

              <div style={{ marginTop: "1em", display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Button type="submit" icon={<LockIcon />}>
                    Acessar sistema
                  </Button>
                </div>
                <div
                  style={{ flex: 1, textAlign: "center", alignSelf: "center" }}
                >
                  <span onClick={() => navigate("/recuperar-senha")}>
                    Esqueci minha senha
                  </span>
                </div>
              </div>
            </ContentLogin>
          </form>
        </LoginArea>
      </Area>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />
    </>
  );
}
