import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Box, Divider, Grid, IconButton, MenuItem } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import CloseIcon from "@mui/icons-material/Close";

import { HeaderDrawer } from "./styled";
import Drawer from "../../../../components/Drawer";
import Label from "../../../../components/Label";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import Button from "../../../../components/Button";
import api from "../../../../services/api";
import SnackBar from "../../../../components/SnackBar";

export default function DrawerCreateUser({
  state,
  setState,
  stores,
  setLoadingFull,
  apiGetAllUsers,
}) {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const userData = useSelector((state) => state.users);

  const apiCreateUser = async (data) => {
    setLoadingFull(true);

    const newUser = {
      name: data.name,
      email: data.email,
      password: data.password,
      admin: data.type,
      store: data.store,
    };

    await api
      .post("/user/register", newUser, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then(() => {
        reset();
        setState(false);
        apiGetAllUsers();
        setTypeSnackBar("success");
        setMessageSnackBar("Usuário criado com sucesso!");
        setOpenSnackBar(true);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  return (
    <>
      <Drawer state={state} setState={setState}>
        <HeaderDrawer>
          <div>
            <PersonAddAltIcon />

            <h5>Cadastrando Usuário</h5>
          </div>

          <IconButton
            onClick={() => setState({ right: false })}
            sx={{ padding: 0.5 }}
          >
            <CloseIcon />
          </IconButton>
        </HeaderDrawer>

        <Divider sx={{ mt: 1 }} />

        <Grid container spacing={{ md: 2, xs: 0 }}>
          <Grid item md={6} xs={12}>
            <Label>Nome</Label>

            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field, ref }) => (
                <Input
                  {...field}
                  error={!!errors.name}
                  autoFocus
                  ref={ref}
                  placeholder="Digite o nome"
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Label>Email</Label>

            <Controller
              name="email"
              control={control}
              rules={{
                required: "Campo necessário",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email inválido",
                },
              }}
              defaultValue=""
              render={({ field, ref }) => (
                <Input
                  {...field}
                  error={!!errors.email}
                  ref={ref}
                  placeholder="Digite o email"
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Label>Senha</Label>

            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
              render={({ field, ref }) => (
                <Input
                  {...field}
                  error={!!errors.password}
                  ref={ref}
                  type="password"
                  placeholder="Digite a senha"
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Label>Confirmar Senha</Label>

            <Controller
              name="password_repeat"
              control={control}
              rules={{
                required: "Campo necessário",
                validate: (value) =>
                  value === password.current || "Senhas não estão iguais.",
              }}
              defaultValue=""
              render={({ field, ref }) => (
                <Input
                  {...field}
                  error={!!errors.password_repeat}
                  ref={ref}
                  type="password"
                  placeholder="Confirme a senha"
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Label>Tipo</Label>

            <Controller
              name="type"
              control={control}
              rules={{
                required: "Campo necessário",
              }}
              defaultValue=""
              render={({ field, ref }) => (
                <Select {...field} displayEmpty error={!!errors.type} ref={ref}>
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value={1}>Administrador</MenuItem>
                  <MenuItem value={0}>Atendente</MenuItem>
                </Select>
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Label>Loja</Label>

            <Controller
              name="store"
              control={control}
              rules={{
                required: "Campo necessário",
              }}
              defaultValue=""
              render={({ field, ref }) => (
                <Select
                  {...field}
                  displayEmpty
                  error={!!errors.store}
                  ref={ref}
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {stores?.length > 0 &&
                    stores?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>

        <Divider sx={{ mt: 3, mb: 3 }} />

        <Box sx={{ textAlign: "end" }}>
          <Button
            onClick={handleSubmit(apiCreateUser)}
            icon={<PersonAddAltIcon />}
          >
            Cadastrar
          </Button>
        </Box>
      </Drawer>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />
    </>
  );
}
