import styled from "styled-components";

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 700;

  margin-bottom: 24px;
`;

export const Card = styled.div`
  border-radius: 12px;
  background-color: #fff;
  padding: 24px;
  width: 100%;

  margin-top: 40px;
`;
