import { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import { Card, CardSelecao, Area } from "./styled";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import api from "../../../../services/api";
import { storageVisit } from "../../../../store/modules/visits/actions";
import Button from "../../../../components/Button";

export default function StepTwo({ step, setStep }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.users);
  const leadData = useSelector((state) => state.leads);
  const visitData = useSelector((state) => state.visits);

  const netStatus = useSelector((state) => state.netStatus);

  const [visitType, setVisitType] = useState([]);

  const getVisitTypes = async () => {
    setLoading(true);

    await api
      .get(`/visit/type`, {
        params: { isnewlead: leadData?.isNew },
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        let list = response.data;

        list = response.data.filter((item) => {
          return Number(item.isCia) === Number(leadData.isCia);
        });

        setVisitType(list);

        localStorage.setItem("visitsType", JSON.stringify(response.data));
      })
      .catch((err) => {
        console.log("ops erro: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelect = (id, isRoulette) => {
    if (step === 2) {
      const newVisitData = {
        ...visitData,
        selectedVisitType: id,
        isRoulette: isRoulette,
      };

      dispatch(storageVisit(newVisitData));

      setStep(3);
    }
  };

  const verifyNetStatusForVisitsTypes = () => {
    if (netStatus) {
      getVisitTypes();
    } else {
      const list = localStorage.getItem("visitsType");
      setVisitType(JSON.parse(list));
    }
  };

  useEffect(() => {
    verifyNetStatusForVisitsTypes();
  }, []);

  return (
    <Area>
      {loading ? (
        <div style={{ marginTop: "1em" }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: "6px" }}
            height={115}
          />

          <Skeleton
            variant="rectangular"
            sx={{ marginTop: "1em", borderRadius: "6px" }}
            height={130}
          />
        </div>
      ) : (
        <>
          {step === 2 && (
            <div
              style={{
                width: "100%",
                marginTop: "1em",
              }}
            >
              <Button
                icon={<ArrowBackIcon />}
                onClick={() => setStep(1)}
                invertIcon={true}
                sx={{
                  width: "100%!important",
                  height: 35,
                  backgroundColor: "#EBADAE!important",
                  color: "#505050!important",
                }}
              >
                Passo anterior
              </Button>
            </div>
          )}

          {visitType?.length > 0 ? (
            visitType?.map((item) => (
              <Card key={item.id}>
                <Grid container spacing={0}>
                  <Grid item md={2} xs={2}>
                    <div
                      style={{
                        display: "flex",
                        width: "fit-content",
                        background: "#F8F8F8",
                        padding: ".5em",
                        borderRadius: 50,
                      }}
                    >
                      <CalendarMonthIcon />
                    </div>
                  </Grid>
                  <Grid item md={10} xs={10}>
                    <h6>{item.title}</h6>
                    <p>{item.description}</p>
                    <CardSelecao
                      onClick={() => handleSelect(item.id, item.isRoulette)}
                      disabled={step > 2}
                      active={visitData?.selectedVisitType === item.id}
                    >
                      {visitData?.selectedVisitType === item.id ? (
                        <CheckCircleIcon />
                      ) : (
                        <div />
                      )}

                      <span>
                        {visitData?.selectedVisitType === item.id
                          ? "Selecionado"
                          : "Selecionar"}
                      </span>
                    </CardSelecao>
                  </Grid>
                </Grid>
              </Card>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              <p>não foi encontrado nenhum tipo de visita.</p>
            </div>
          )}
        </>
      )}
    </Area>
  );
}
