import { useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";
import { Controller, useForm } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import api from "../../services/api";
import SnackBar from "../../components/SnackBar";
import LoadingFull from "../../components/LoadingFull";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Label from "../../components/Label";
import { Area, BackgroundImage, LoginArea, ContentLogin } from "./styled";

export default function RecoverNewPassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({});

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const [successSendEmail, setSuccessSendEmail] = useState(false);

  const [loadingFull, setLoadingFull] = useState(false);

  const password = useRef({});
  password.current = watch("password", "");

  const apiChangePassword = async (data) => {
    setLoadingFull(true);

    await api
      .post("/user/change-password", {
        token: searchParams.get("token"),
        email: data.email,
        password: data.password,
      })
      .then(() => {
        setSuccessSendEmail(true);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  return (
    <>
      <Area>
        <BackgroundImage />
        <LoginArea style={{ flex: 1 }}>
          {!successSendEmail ? (
            <form onSubmit={handleSubmit(apiChangePassword)}>
              <ContentLogin>
                <h1>Estamos quase lá</h1>
                <h6>
                  Informe seu email e a nova senha para prosseguir com a
                  alteração da sua senha de acesso!
                </h6>

                <Label>Email</Label>

                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      error={!!errors.email}
                      type="email"
                      ref={ref}
                      placeholder="Digite o seu email"
                    />
                  )}
                />

                <Label>Senha</Label>

                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      error={!!errors.password}
                      ref={ref}
                      type="password"
                      placeholder="Digite a sua nova senha"
                    />
                  )}
                />

                <Label>Confirmação de senha</Label>

                <Controller
                  name="password_repeat"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) =>
                      value === password.current || "Senhas não estão iguais.",
                  }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      error={!!errors.password_repeat}
                      ref={ref}
                      type="password"
                      placeholder="Digite a novamente a sua nova senha"
                    />
                  )}
                />

                <div style={{ marginTop: "1em", display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Button type="submit" icon={<CachedIcon />}>
                      Alterar senha
                    </Button>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      alignSelf: "center",
                    }}
                  >
                    <span onClick={() => navigate("/")}>
                      Lembrei minha senha
                    </span>
                  </div>
                </div>
              </ContentLogin>
            </form>
          ) : (
            <ContentLogin style={{ textAlign: "center" }}>
              <CheckCircleIcon
                style={{ fontSize: 120, color: "var(--main-color)" }}
              />
              <h3>Senha alterada com sucesso!</h3>
              <p>Entre na sua conta utilizando a nova senha.</p>
              <span onClick={() => navigate("/")}>Entrar</span>
            </ContentLogin>
          )}
        </LoginArea>
      </Area>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />
    </>
  );
}
