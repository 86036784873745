import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Tooltip, Container } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

import Header from "../../components/Header";
import Button from "../../components/Button";
import SnackBar from "../../components/SnackBar";
import LoadingFull from "../../components/LoadingFull";
import api from "../../services/api";
import { logar } from "../../store/modules/users/actions";
import FloatingButton from "../../components/FloatingButton";
import Table from "../../components/Table";

export default function VisitsAwayMode() {
  const userData = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const [loadingFull, setLoadingFull] = useState(false);

  const [visits, setVisits] = useState([]);

  const getVisitsAwayMode = () => {
    let list = [];

    if (localStorage.getItem("visitsAwayMode")) {
      list = JSON.parse(localStorage.getItem("visitsAwayMode"));
    }

    setVisits([...list]);
  };

  const removeVisitInVisitsAwayMode = (index) => {
    let listVisitsAwayMode = localStorage.getItem("visitsAwayMode");

    let newListVisitsAwayMode = JSON.parse(listVisitsAwayMode).filter(
      (item, i) => {
        return index !== i;
      }
    );

    localStorage.setItem(
      "visitsAwayMode",
      JSON.stringify(newListVisitsAwayMode)
    );

    getVisitsAwayMode();
  };

  const sendAllLeadAndVisit = async () => {
    let listVisitsAwayMode = JSON.parse(localStorage.getItem("visitsAwayMode"));

    setLoadingFull(true);

    if (listVisitsAwayMode) {
      if (listVisitsAwayMode.length > 0) {
        for (let i = 0; i < listVisitsAwayMode.length; i++) {
          const leadId = await apiHandleLeadReturnId(listVisitsAwayMode[i]);

          if (leadId) {
            await apiHandleVisit(leadId, listVisitsAwayMode[i]);
          }
        }
      } else {
        getVisitsAwayMode();
      }
    } else {
      getVisitsAwayMode();
    }

    setLoadingFull(false);

    getVisitsAwayMode();
  };

  const apiHandleLeadReturnId = async (lead) => {
    const newLead = {
      name: lead?.name,
      email: lead?.email,
    };

    return await api
      .post("/lead", newLead, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        return response.data.id;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const apiHandleVisit = async (leadId, visit) => {
    const newVisit = {
      visit: {
        CorrectorId: visit?.corrector?.CorrectorId,
        correctorName: visit?.corrector?.newCorrectorName || null,
        salesCompanyId: visit?.corrector?.salesCompanyId || null,
        VisitTypeId: visit?.visitsType?.id,
        day: new Date(),
        LeadId: leadId,
      },
      rouletteId: visit?.corrector?.RouletteId,
    };

    api
      .post("/visit", newVisit, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then(() => {
        let listVisitsAwayMode = JSON.parse(
          localStorage.getItem("visitsAwayMode")
        );

        if (listVisitsAwayMode) {
          let newArray = listVisitsAwayMode.filter((item) => {
            return item.uuid !== visit.uuid;
          });

          localStorage.setItem("visitsAwayMode", JSON.stringify(newArray));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getVisitsAwayMode();
      });
  };

  const apiCreateLeadAndVisit = async (index, leadAndVisit) => {
    let listVisitsAwayMode = JSON.parse(localStorage.getItem("visitsAwayMode"));

    if (!listVisitsAwayMode) return null;

    if (!listVisitsAwayMode.length > 0) {
      setTypeSnackBar("error");
      setMessageSnackBar(
        "Todas visitas foram registradas, reinicie a página para atualizar a lista."
      );
      setOpenSnackBar(true);

      return null;
    }

    setLoadingFull(true);

    const newLead = {
      name: leadAndVisit?.name,
      email: leadAndVisit?.email,
    };

    await api
      .post("/lead", newLead, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        const newVisit = {
          visit: {
            CorrectorId: leadAndVisit?.corrector?.CorrectorId,
            correctorName: leadAndVisit?.corrector?.newCorrectorName || null,
            salesCompanyId: leadAndVisit?.corrector?.salesCompanyId || null,
            VisitTypeId: leadAndVisit?.visitsType?.id,
            day: new Date(),
            LeadId: response?.data?.id,
          },
          rouletteId: leadAndVisit?.corrector?.RouletteId,
        };

        api
          .post("/visit", newVisit, {
            headers: { Authorization: `Bearer ${userData.token}` },
          })
          .then((anotherResponse) => {
            let listVisitsAwayMode = localStorage.getItem("visitsAwayMode");

            if (listVisitsAwayMode) {
              listVisitsAwayMode = JSON.parse(listVisitsAwayMode).filter(
                (item, i) => {
                  return index !== i;
                }
              );

              listVisitsAwayMode.length > 0
                ? localStorage.setItem(
                    "visitsAwayMode",
                    JSON.stringify(listVisitsAwayMode)
                  )
                : localStorage.removeItem("visitsAwayMode");
            }

            setTypeSnackBar("success");
            setMessageSnackBar("Visita registrada");
            setOpenSnackBar(true);

            getVisitsAwayMode();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  useEffect(() => {
    if (userData.redirect === "/visitas-modo-ausente") {
      dispatch(logar({ ...userData, redirect: "" }));
    }
  }, []);

  useEffect(() => {
    getVisitsAwayMode();
  }, []);

  return (
    <>
      <Header />

      <Container maxWidth="xl">
        <h1
          style={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "31px",
            color: "#000000",
          }}
        >
          Visitas modo ausente
        </h1>

        <div
          style={{
            marginTop: "2em",
            justifyContent: "flex-end",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "flex-start",
          }}
        >
          {visits?.length > 0 && (
            <Button
              onClick={() => sendAllLeadAndVisit()}
              style={{ height: "40px" }}
              icon={<SaveIcon />}
            >
              Registrar todas visitas
            </Button>
          )}
        </div>

        <Table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Corretor</th>
              <th>Tipo visita</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {visits?.length > 0 &&
              visits?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.name}</td>
                  <td>{item?.email}</td>
                  <td>
                    {item?.corrector?.name || item?.corrector?.newCorrectorName}
                  </td>
                  <td>{item?.visitsType?.title}</td>
                  <td style={{ padding: "0" }}>
                    <Tooltip title="Registrar visita" disableInteractive>
                      <IconButton
                        onClick={() => {
                          apiCreateLeadAndVisit(index, item);
                        }}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Excluir visita" disableInteractive>
                      <IconButton
                        onClick={() => {
                          removeVisitInVisitsAwayMode(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {(visits?.length === 0 || !visits) && (
          <div style={{ textAlign: "center" }}>
            <p>não foi encontrado nenhuma visita</p>
          </div>
        )}
      </Container>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />

      <FloatingButton>Nova Visita</FloatingButton>
    </>
  );
}
