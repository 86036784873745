export function storageLead(leads) {
  return {
    type: "STORAGE",
    leads,
  };
}

export function cleanLead() {
  return {
    type: "CLEAN",
  };
}
