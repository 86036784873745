import { useEffect, useState } from "react";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
import { Grid, IconButton, MenuItem, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import StoreIcon from "@mui/icons-material/Store";

import Header from "../../components/Header";
import Button from "../../components/Button";
import { Badge, DialogButtons, DialogArea } from "./styled";
import DrawerCreateUser from "./components/DrawerCreateUser";
import DrawerUpdateUser from "./components/DrawerUpdateUser";
import Dialog from "../../components/Dialog";
import SnackBar from "../../components/SnackBar";
import LoadingFull from "../../components/LoadingFull";
import api from "../../services/api";
import Select from "../../components/Select";
import Label from "../../components/Label";
import FloatingButton from "../../components/FloatingButton";
import Table from "../../components/Table";

export default function Users() {
  const userData = useSelector((state) => state.users);

  const [drawerStateCreate, setDrawerStateCreate] = useState(false);
  const [drawerStateUpdate, setDrawerStateUpdate] = useState(false);

  const [openDialogActive, setOpenDialogActive] = useState(false);
  const [openDialogDesactive, setOpenDialogDesactive] = useState(false);
  const [openDialogStore, setOpenDialogStore] = useState(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const [loadingFull, setLoadingFull] = useState(false);

  const [users, setUsers] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeSelect, setStoreSelect] = useState("");
  const [userSelected, setUserSelected] = useState("");

  const apiGetAllStores = async () => {
    setLoadingFull(true);

    await api
      .get("/store", { headers: { Authorization: `Bearer ${userData.token}` } })
      .then((response) => {
        setStores(response.data);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const apiActiveUser = async () => {
    setLoadingFull(true);
    setOpenDialogActive(false);

    await api
      .put(`/user/${userSelected.id}/active/`, null, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then(() => {
        apiGetAllUsers();
        setTypeSnackBar("success");
        setMessageSnackBar("Usuário ativado com sucesso!");
        setOpenSnackBar(true);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const apiUserChangeStore = async () => {
    setLoadingFull(true);
    setOpenDialogStore(false);
    setStoreSelect("");

    await api
      .put(
        `/user/${userSelected.id}/change-store/`,
        { storeId: storeSelect },
        { headers: { Authorization: `Bearer ${userData.token}` } }
      )
      .then(() => {
        apiGetAllUsers();
        setTypeSnackBar("success");
        setMessageSnackBar("Alterado loja do usuário com sucesso!");
        setOpenSnackBar(true);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const apiDesactiveUser = async () => {
    setLoadingFull(true);
    setOpenDialogDesactive(false);

    await api
      .put(`/user/${userSelected.id}/desactive/`, null, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then(() => {
        apiGetAllUsers();
        setTypeSnackBar("success");
        setMessageSnackBar("Usuário desativado com sucesso!");
        setOpenSnackBar(true);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const apiGetAllUsers = async () => {
    setLoadingFull(true);

    await api
      .get("/user", { headers: { Authorization: `Bearer ${userData.token}` } })
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  useEffect(() => {
    apiGetAllUsers();
    apiGetAllStores();
  }, []);

  return (
    <>
      <Header />

      <Container maxWidth="xl">
        <h1
          style={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "31px",
            color: "#000000",
          }}
        >
          Usuários
        </h1>

        <div style={{ textAlign: "end" }}>
          <Button
            onClick={() => setDrawerStateCreate({ right: true })}
            sx={{ mb: 2 }}
            icon={<PersonAddAltIcon />}
          >
            Cadastrar Usuário
          </Button>
        </div>

        <Table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Tipo de usuário</th>
              <th>Loja associada</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {users?.length > 0 &&
              users?.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.admin ? "Admininistrador" : "Atendente"}</td>
                  <td>{item.StoreUsers[0]?.Store?.name}</td>
                  <td>
                    <Badge active={item.active}>
                      {item.active ? "Ativo" : "Inativo"}
                    </Badge>
                  </td>
                  <td style={{ padding: 0 }}>
                    <Tooltip title="Editar Usuário" disableInteractive>
                      <IconButton
                        onClick={() => {
                          setUserSelected(item);
                          setDrawerStateUpdate({ right: true });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Trocar Loja Usuário" disableInteractive>
                      <IconButton
                        onClick={() => {
                          setUserSelected(item);
                          setStoreSelect(item.StoreUsers[0].StoreId);
                          setOpenDialogStore(true);
                        }}
                      >
                        <StoreIcon />
                      </IconButton>
                    </Tooltip>

                    {item.active ? (
                      <Tooltip title="Desativar Usuário" disableInteractive>
                        <IconButton
                          onClick={() => {
                            setUserSelected(item);
                            setOpenDialogDesactive(true);
                          }}
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Ativar Usuário" disableInteractive>
                        <IconButton
                          onClick={() => {
                            setUserSelected(item);
                            setOpenDialogActive(true);
                          }}
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>

      <Dialog open={openDialogActive} setOpen={setOpenDialogActive}>
        <DialogArea>
          <h1>Confirmação</h1>
          <p>Deseja realmente ativar esse usuário?</p>

          <DialogButtons>
            <button onClick={() => apiActiveUser()}>Ativar</button>

            <button onClick={() => setOpenDialogActive(false)}>Cancelar</button>
          </DialogButtons>
        </DialogArea>
      </Dialog>

      <Dialog open={openDialogDesactive} setOpen={setOpenDialogDesactive}>
        <DialogArea>
          <h1>Confirmação</h1>
          <p>Deseja realmente desativar esse usuário?</p>

          <DialogButtons>
            <button onClick={() => apiDesactiveUser()}>Desativar</button>

            <button onClick={() => setOpenDialogDesactive(false)}>
              Cancelar
            </button>
          </DialogButtons>
        </DialogArea>
      </Dialog>

      <Dialog width={450} open={openDialogStore} setOpen={setOpenDialogStore}>
        <DialogArea>
          <h1>Alterar Loja</h1>
          <p>
            Deseja alterar a loja associada desse usuário, caso sim, qual loja?
          </p>

          <Grid pt={1} mb={2} container spacing={2}>
            <Grid item md={12} xs={12}>
              <Label>Loja</Label>

              <Select
                value={storeSelect}
                onChange={(e) => setStoreSelect(e.target.value)}
                displayEmpty
                error={!!storeSelect}
              >
                <MenuItem value={""}>
                  <em>Selecione</em>
                </MenuItem>
                {stores?.length > 0 &&
                  stores?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>

          <DialogButtons>
            <button onClick={() => apiUserChangeStore()}>Alterar Loja</button>

            <button onClick={() => setOpenDialogStore(false)}>Cancelar</button>
          </DialogButtons>
        </DialogArea>
      </Dialog>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />

      <DrawerCreateUser
        stores={stores}
        state={drawerStateCreate}
        setState={setDrawerStateCreate}
        setLoadingFull={setLoadingFull}
        apiGetAllUsers={apiGetAllUsers}
      />

      <DrawerUpdateUser
        apiGetAllUsers={apiGetAllUsers}
        setLoadingFull={setLoadingFull}
        state={drawerStateUpdate}
        setState={setDrawerStateUpdate}
        userSelected={userSelected}
      />

      <FloatingButton>Nova Visita</FloatingButton>
    </>
  );
}
