import { useState } from "react";
import { Container } from "@mui/material";
import { useDispatch } from "react-redux";

import Header from "../../components/Header";
import SnackBar from "../../components/SnackBar";
import { Card, Title } from "./styled";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

export default function Cpm24() {
  const [step, setStep] = useState(1);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const [externToken, setExternToken] = useState("");

  return (
    <>
      <Header />

      <Container maxWidth="sm">
        <Card>
          <Title>Campanha 2024 - Apê Grátis O Jogo</Title>

          {step === 1 ? (
            <StepOne
              setStep={setStep}
              setOpenSnackBar={setOpenSnackBar}
              setMessageSnackBar={setMessageSnackBar}
              setTypeSnackBar={setTypeSnackBar}
              externToken={externToken}
              setExternToken={setExternToken}
            />
          ) : null}

          {step === 2 ? (
            <StepTwo
              setStep={setStep}
              setOpenSnackBar={setOpenSnackBar}
              setMessageSnackBar={setMessageSnackBar}
              setTypeSnackBar={setTypeSnackBar}
              externToken={externToken}
            />
          ) : null}
        </Card>
      </Container>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />
    </>
  );
}
