import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Autocomplete, Skeleton, TextField } from "@mui/material";

import api from "../../../../services/api";
import { Card, CardSelecao, Badge, Area } from "./styled";
import { storageVisit } from "../../../../store/modules/visits/actions";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";

export default function StepThree({ step, setStep }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const userData = useSelector((state) => state.users);
  const leadData = useSelector((state) => state.leads);
  const visitData = useSelector((state) => state.visits);
  const netStatus = useSelector((state) => state.netStatus);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const [correctorSelected, setCorrectorSelected] = useState({});

  const [correctors, setCorrectors] = useState([]);
  const [salesCompanies, setSalesCompanies] = useState([]);

  const getCorretorRoulette = async () => {
    setLoading(true);

    await api
      .get(`/corrector/roulette`, {
        params: {
          isroulette: visitData.isRoulette || null,
        },
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        let list = response.data;

        if (!visitData.isRoulette && leadData.isCia) {
          list = list?.filter(
            (item) => Number(item.cia) === Number(leadData.isCia)
          );
        }

        setCorrectors(list);

        if (visitData.isRoulette) {
          localStorage.setItem("corrector", JSON.stringify(response.data));
        }
      })
      .catch((err) => {
        console.log("ops erro: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const apiGetSalesCompanies = async () => {
    setLoading(true);

    await api
      .get(`/salescompany`, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        setSalesCompanies(response.data);
        localStorage.setItem("salesCompany", JSON.stringify(response.data));
      })
      .catch((err) => {
        console.log("ops erro: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelect = (rouletteId, correctorId, data) => {
    if (step === 3) {
      const addInfoVisit = {
        ...visitData,
        selectedRoulette: rouletteId || null,
        selectedCorrector: correctorId || null,
        selectedCorrectorName: data?.correctorName || null,
        selectedSalesCompanyId: data?.salesCompany?.id || null,
        selectedSalesManager: data?.salesManager || null,
      };

      dispatch(storageVisit(addInfoVisit));

      setStep(4);
    }
  };

  const verifyNetStatusForGetCorrectors = () => {
    if (netStatus) {
      getCorretorRoulette();
    } else {
      let list = [];

      if (localStorage.getItem("corrector")) {
        list = JSON.parse(localStorage.getItem("corrector"));
      }

      setCorrectors(list);
    }
  };

  const verifyNetStatusForGetSalesCompany = () => {
    if (netStatus) {
      apiGetSalesCompanies();
    } else {
      let list = [];

      if (localStorage.getItem("salesCompany")) {
        list = JSON.parse(localStorage.getItem("salesCompany"));
      }

      setSalesCompanies(list);
    }
  };

  const onChangeCorrectors = (value) => {
    if (value) {
      setStep(4);

      const addInfoVisit = {
        ...visitData,
        selectedRoulette: null,
        selectedCorrector: value.id || "",
      };

      dispatch(storageVisit(addInfoVisit));
    }

    setCorrectorSelected(value);
  };

  const resetCorrector = () => {
    const addInfoVisit = {
      isRoulette: visitData.isRoulette,
      selectedVisitType: visitData.selectedVisitType,
    };

    dispatch(storageVisit(addInfoVisit));
  };

  useEffect(() => {
    verifyNetStatusForGetCorrectors();
    verifyNetStatusForGetSalesCompany();
  }, []);

  return (
    <Area>
      {loading ? (
        <div style={{ marginTop: "1em" }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: "6px" }}
            height={100}
          />

          <Skeleton
            variant="rectangular"
            sx={{ marginTop: "1em", borderRadius: "6px" }}
            height={115}
          />
        </div>
      ) : (
        <>
          {step === 3 && (
            <div
              style={{
                width: "100%",
                marginTop: "1em",
              }}
            >
              <Button
                icon={<ArrowBackIcon />}
                onClick={() => {
                  setStep(2);
                  resetCorrector();
                }}
                invertIcon={true}
                sx={{
                  width: "100%!important",
                  height: 35,
                  backgroundColor: "#EBADAE!important",
                  color: "#505050!important",
                }}
              >
                Passo anterior
              </Button>
            </div>
          )}

          {visitData.isRoulette ? (
            <>
              {correctors?.length > 0 &&
                correctors?.map((item, index) => (
                  <Card key={item.id}>
                    <div
                      style={{
                        paddingBottom: 10,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>
                        {item?.Corrector?.name || item?.name}{" "}
                        {item?.Corrector?.salesManager && (
                          <span>| {item?.Corrector?.salesManager}</span>
                        )}
                      </h6>

                      {index === 0 && <Badge>Corretor da vez</Badge>}
                    </div>

                    <div
                      style={{
                        paddingBottom: 15,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <p>
                        {item?.Corrector?.SalesCompany?.name ||
                          item?.SalesCompany?.name}
                      </p>

                      <CircleIcon
                        sx={{
                          marginRight: 1,
                          marginLeft: 1,
                          alignSelf: "center",
                          fontSize: 5,
                        }}
                      />

                      {leadData.isCia ? (
                        <p>CIA</p>
                      ) : (
                        <>
                          {visitData.isRoulette ? (
                            <p>
                              <b>{item.attended}</b> Clientes atendidos
                            </p>
                          ) : (
                            <p></p>
                          )}
                        </>
                      )}
                    </div>

                    <CardSelecao
                      onClick={() =>
                        handleSelect(
                          visitData?.isRoulette ? item.id : null,
                          visitData?.isRoulette
                            ? item.CorrectorId
                            : item.id || item.id
                        )
                      }
                      disabled={step > 3}
                      active={
                        visitData?.selectedCorrector ===
                        (visitData?.isRoulette ? item.CorrectorId : item.id)
                      }
                    >
                      {visitData?.selectedCorrector ===
                      (visitData?.isRoulette ? item.CorrectorId : item.id) ? (
                        <CheckCircleIcon />
                      ) : (
                        <div />
                      )}

                      <span>
                        {visitData?.selectedCorrector ===
                        (visitData?.isRoulette ? item.CorrectorId : item.id)
                          ? "Selecionado"
                          : "Selecionar"}
                      </span>
                    </CardSelecao>
                  </Card>
                ))}
            </>
          ) : (
            <>
              {!leadData.isCia && (
                <Card>
                  <div
                    style={{
                      paddingBottom: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h6>Selecionar corretor</h6>
                  </div>

                  <div
                    style={{
                      paddingBottom: 15,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <p>Procure pelo corretor</p>
                  </div>

                  <Autocomplete
                    fullWidth
                    disabled={step > 3}
                    noOptionsText="Nenhum corretor encontrado"
                    value={visitData?.selectedCorrector}
                    onChange={(event, newValue) => {
                      onChangeCorrectors(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value
                    }
                    options={correctors}
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecione o corretor" />
                    )}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option?.id}>
                          {option?.name} | {option?.salesManager}
                        </li>
                      );
                    }}
                  />
                </Card>
              )}
            </>
          )}

          <Card>
            <div
              style={{
                paddingBottom: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h6>Outro corretor</h6>
            </div>

            <div
              style={{
                paddingBottom: 15,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <p>Digite nome do corretor</p>
            </div>

            <Controller
              name="correctorName"
              control={control}
              rules={{
                required:
                  visitData.selectedCorrector === undefined ||
                  visitData.selectedCorrector === null ||
                  visitData.selectedCorrector === 0
                    ? 1
                    : 0,
              }}
              defaultValue=""
              render={({ field, ref }) => (
                <Input
                  {...field}
                  disabled={step > 3}
                  error={!!errors?.correctorName}
                  ref={ref}
                  sx={{ mb: 2 }}
                  placeholder="Digite o nome completo do corretor"
                />
              )}
            />

            {leadData.isCia && (
              <Controller
                name="salesManager"
                control={control}
                rules={{
                  required:
                    visitData.selectedCorrector === undefined ||
                    visitData.selectedCorrector === null ||
                    visitData.selectedCorrector === 0
                      ? 1
                      : 0,
                }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    disabled={step > 3}
                    error={!!errors.salesManager}
                    ref={ref}
                    sx={{ mb: 2 }}
                    placeholder="Digite o nome do gerente de vendas"
                  />
                )}
              />
            )}

            <Controller
              name="salesCompany"
              control={control}
              rules={{
                required:
                  visitData.selectedCorrector === undefined ||
                  visitData.selectedCorrector === null ||
                  visitData.selectedCorrector === 0
                    ? 1
                    : 0,
              }}
              render={({ field, ref }) => (
                <Autocomplete
                  {...field}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                  }}
                  fullWidth
                  disabled={step > 3}
                  noOptionsText="Nenhuma empresa de vendas encontrada"
                  sx={{
                    mb: 2,
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option?.id === value;
                  }}
                  options={salesCompanies}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecione a empresa de vendas"
                      error={!!(errors.salesCompany && step === 3)}
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option?.id}>
                        {option?.name}
                      </li>
                    );
                  }}
                />
              )}
            />

            <CardSelecao
              onClick={handleSubmit((data) => handleSelect(0, 0, data))}
              disabled={step > 3}
              active={
                visitData?.selectedCorrector === 0 ||
                visitData?.selectedCorrector === null
              }
            >
              {visitData?.selectedCorrector === 0 ||
              visitData?.selectedCorrector === null ? (
                <CheckCircleIcon />
              ) : (
                <div />
              )}

              <span>
                {visitData?.selectedCorrector === 0 ||
                visitData?.selectedCorrector === null
                  ? "Selecionado"
                  : "Selecionar"}
              </span>
            </CardSelecao>
          </Card>
        </>
      )}
    </Area>
  );
}
