import { useEffect, useState } from "react";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Header from "../../components/Header";
import SnackBar from "../../components/SnackBar";
import LoadingFull from "../../components/LoadingFull";
import api from "../../services/api";
import Table from "../../components/Table";

export default function Stores() {
  const userData = useSelector((state) => state.users);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success | info

  const [loadingFull, setLoadingFull] = useState(false);

  const [stores, setStores] = useState([]);

  const apiGetAllStores = async () => {
    setLoadingFull(true);

    api
      .get("/store", { headers: { Authorization: `Bearer ${userData.token}` } })
      .then((response) => {
        setStores(response.data);
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setMessageSnackBar(
          error?.response?.data?.message || "Tente novamente mais tarde"
        );
        setTypeSnackBar("error");
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const copyToClipboard = (slug) => {
    navigator.clipboard.writeText(
      `https://visitas.planoeplano.app/cliente/loja/${slug}`
    );
    setOpenSnackBar(true);
    setMessageSnackBar("Link copiado");
    setTypeSnackBar("success");
  };

  useEffect(() => {
    apiGetAllStores();
  }, []);

  return (
    <>
      <Header />

      <Container maxWidth="xl">
        <h1
          style={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "31px",
            color: "#000000",
          }}
        >
          Lojas
        </h1>

        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Sales Force ID</th>
              <th>Nome</th>
              <th>Slug</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {stores?.length > 0 &&
              stores?.map((item) => (
                <tr key={item.id}>
                  <td>{item?.id}</td>
                  <td>{item?.sfId}</td>
                  <td>{item?.name}</td>
                  <td>{item?.slug}</td>
                  <td style={{ padding: 0 }}>
                    <Tooltip
                      title="Copiar link"
                      sx={{
                        ":hover": {
                          opacity: 0.6,
                        },
                      }}
                      disableInteractive
                    >
                      <IconButton
                        onClick={() => {
                          copyToClipboard(item?.slug);
                        }}
                      >
                        <ContentCopyIcon
                          sx={{
                            color: item.EnterpriseId ? "#57c957" : "#8f8e8e",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {stores?.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <p>não foi encontrado nenhuma loja</p>
          </div>
        )}
      </Container>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />
    </>
  );
}
