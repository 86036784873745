import * as React from "react";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";

import { DialogCustom } from "./styled";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dialog({ open, setOpen, children, width }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DialogCustom
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        ".MuiPaper-root": {
          maxWidth: "800px",
        },
      }}
    >
      <Box
        sx={{ padding: "1.5em", minWidth: { md: width || 450, sm: "100%" } }}
      >
        {children}
      </Box>
    </DialogCustom>
  );
}
