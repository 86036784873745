import { combineReducers } from "redux";

import users from "./users/reducer";
import leads from "./leads/reducer";
import visits from "./visits/reducer";
import netStatus from "./netStatus/reducer";

export default combineReducers({
  users,
  leads,
  visits,
  netStatus,
});
