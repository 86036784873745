import { Autocomplete, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Dialog from "../../../components/Dialog";
import api from "../../../services/api";
import { DialogArea, DialogButtons } from "./styled";

export default function ModalSelectEnterprise({
  open,
  setOpen,
  setLoadingFull,
  visitId,
  apiGetAllVisits,
  setTypeSnackBar,
  setMessageSnackBar,
  setOpenSnackBar,
  enterpriseVisit,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const userData = useSelector((state) => state.users);

  const [enterprises, setEnterprises] = useState([]);

  const apiGetAllEnterprises = async () => {
    setLoadingFull(true);

    await api
      .get("/enterprises", {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        setEnterprises(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const apiUpdateEnterpriseVisit = async (data) => {
    setLoadingFull(true);

    setOpen(false);

    await api
      .put(
        `/visit/${visitId}`,
        { EnterpriseId: data?.enterprise?.id },
        {
          headers: { Authorization: `Bearer ${userData.token}` },
        }
      )
      .then((response) => {
        apiGetAllVisits();

        setTypeSnackBar("success");
        setMessageSnackBar(
          "O empreendimento da visita foi atualizado com sucesso"
        );
        setOpenSnackBar(true);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setValue("enterprise", null);
        setLoadingFull(false);
      });
  };

  useEffect(() => {
    if (open) {
      apiGetAllEnterprises();

      setValue("enterprise", enterpriseVisit || null);
    }
  }, [open, enterpriseVisit]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Dialog width={450} open={open} setOpen={setOpen}>
        <DialogArea>
          <h1>Informar empreendimento</h1>
          <p>Deseja informar o empreendimento dessa visita?</p>

          <form onSubmit={handleSubmit(apiUpdateEnterpriseVisit)}>
            <Grid pt={1} mb={2} container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="enterprise"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name }, ref }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value || null}
                      ref={ref}
                      name={name}
                      fullWidth
                      noOptionsText="Nenhum empreendimento encontrado"
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(e, v) => {
                        return e.id === v.id;
                      }}
                      options={enterprises}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Selecione o empreendimento"
                          error={!!errors["enterprise"]}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <DialogButtons>
              <button type="submit">Informar empreendimento</button>

              <button type="button" onClick={() => setOpen(false)}>
                Cancelar
              </button>
            </DialogButtons>
          </form>
        </DialogArea>
      </Dialog>
    </>
  );
}
