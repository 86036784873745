import styled from "styled-components";

export const TableCustom = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  thead {
    background: #e9e9e9;

    th {
      padding: 1em 0.5em;
      font-size: 14px;
      position: sticky;
      top: 0;
      background: #e9e9e9;
    }

    th:first-child {
      text-align: left;
    }
  }

  tbody {
    tr {
      :hover {
        background: #80808012;
      }
    }

    td {
      border-bottom: 1px solid #dbdbdb;
      text-align: center;
      padding: 1em 0.5em;
      font-size: 14px;
    }

    td:first-child {
      text-align: left;
    }
  }

  @media (max-width: 448px) {
    thead th {
      min-width: 150px;
    }

    tbody td {
      min-width: 150px;
    }
  }
`;
