import { useEffect } from "react";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import Dialog from "../../../components/Dialog";
import Input from "../../../components/Input";
import api from "../../../services/api";
import { DialogArea, DialogButtons } from "./styled";

export default function ModalEditDate({
  open,
  setOpen,
  setLoadingFull,
  visitId,
  visitDate,
  apiGetAllVisits,
  setTypeSnackBar,
  setMessageSnackBar,
  setOpenSnackBar,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const userData = useSelector((state) => state.users);

  const apiUpdateVisitDate = async (data) => {
    setLoadingFull(true);

    setOpen(false);

    let newData = {
      createdAt: data.visitDate,
    };

    await api
      .put(`/visit/${visitId}`, newData, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        apiGetAllVisits();

        setTypeSnackBar("success");
        setMessageSnackBar("Data de atendimento atualizado com sucesso");
        setOpenSnackBar(true);
      })
      .catch((err) => {
        console.log(err);

        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setValue("visitDate", "");
        setLoadingFull(false);
      });
  };

  useEffect(() => {
    setValue("visitDate", visitDate);
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Dialog width={450} open={open} setOpen={setOpen}>
        <DialogArea>
          <h1>Data de atendimento</h1>
          <p>Deseja alterar a data de atendimento?</p>

          <form onSubmit={handleSubmit(apiUpdateVisitDate)}>
            <Grid pt={1} mb={2} container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="visitDate"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      error={!!errors.visitDate}
                      type="datetime-local"
                      sx={{ mb: 1 }}
                      fullWidth
                      ref={ref}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <DialogButtons>
              <button type="submit">Alterar data de atendimento</button>

              <button type="button" onClick={() => setOpen(false)}>
                Cancelar
              </button>
            </DialogButtons>
          </form>
        </DialogArea>
      </Dialog>
    </>
  );
}
