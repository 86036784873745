import { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import Input from "../../../components/Input";
import Button from "../../../components/Button";
import apiCpm2024 from "../../../services/apiCpm2024";

export default function StepTwo({
  setStep,
  setOpenSnackBar,
  setMessageSnackBar,
  setTypeSnackBar,
  externToken,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const userData = useSelector((state) => state.users);

  const [loading, setLoading] = useState(false);

  const apiHandleSendTerm = (data) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("atendent_id", userData?.id);

    apiCpm2024
      .post("/visitas/upload-term", formData, {
        headers: {
          Authorization: `Bearer ${externToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setOpenSnackBar(true);
        setTypeSnackBar("success");
        setMessageSnackBar("Termo enviado");
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setTypeSnackBar("error");
        setMessageSnackBar(
          error?.response?.data?.message || "Tente novamente mais tarde!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(apiHandleSendTerm)}>
      <p style={{ margin: 0 }}>2. Envie o termo</p>

      <Controller
        name="file"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field, ref }) => (
          <Input
            error={!!errors.file}
            ref={ref}
            type="file"
            sx={{
              mt: "8px",
            }}
            onChange={(e) => field.onChange(e.target.files)}
            helperText={errors.file ? "Campo obrigatório" : ""}
          />
        )}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button type="submit" sx={{ mt: 2 }} disabled={loading}>
          {loading ? "Enviar..." : "Enviar termo"}
        </Button>
      </Box>

      <Divider
        sx={{
          my: "32px",
        }}
      />

      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          textDecoration: "underline",
          cursor: "pointer",
          width: "max-content",
          "&:hover": {
            color: "blue",
          },
        }}
        onClick={() => setStep(1)}
      >
        Voltar
      </Typography>
    </form>
  );
}
