import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingFull({ open }) {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={open}>
      <CircularProgress sx={{ color: "var(--main-color)" }} color="inherit" />
    </Backdrop>
  );
}
