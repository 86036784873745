export function storageVisit(visits) {
  return {
    type: "CREATE",
    visits,
  };
}

export function cleanVisit() {
  return {
    type: "CLEANER",
  };
}
