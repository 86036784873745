import styled from "styled-components";

export const Area = styled.main`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;

  h1 {
    font-weight: 700;
    font-size: 22px;
    color: #000000;
    margin: 0;
  }

  h6 {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5em;
    color: #505050;
  }

  span {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--main-color);
    text-decoration: underline;
    text-underline-offset: 2px;

    :hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 920px) {
    span {
      font-size: 13px;
    }

    flex-direction: column;
  }
`;

export const BackgroundImage = styled.div`
  flex: 1;
  width: 100%;
  background-image: url("/assets/images/agradecemosSuaVisita.png");
  background-size: cover;

  div {
    display: flex;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 920px) {
    background-position: center;
  }
`;

export const LoginArea = styled.div`
  flex: 1;
  align-self: center;
`;

export const ContentLogin = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 920px) and (min-width: 468px) {
    background: white;
    padding: 2em;
    width: 100%;
    border-radius: 12px;
  }

  @media (max-width: 468px) {
    background: white;
    padding: 1em;
    width: auto;
    border-radius: 5px;
  }
`;
