import { Container } from "@mui/material";
import { Link } from "react-router-dom";

export default function Error() {
  return (
    <>
      <Container maxWidth="xl">
        <div
          style={{
            width: "100%",
            height: "100vh",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", alignSelf: "center" }}>
            <img
              src="/assets/images/logo.png"
              alt="Logo Plano&Plano"
              width="200"
            />

            <hr style={{ marginLeft: 25, marginRight: 25 }} />

            <p style={{ alignSelf: "center", marginTop: 0, marginBottom: 0 }}>
              Página não encontrada
            </p>
          </div>

          <Link style={{ marginTop: 50 }} to="/">
            Voltar para o Início
          </Link>
        </div>
      </Container>
    </>
  );
}
