export default function netStatus(state = true, action) {
  switch (action.type) {
    case "STATUS":
      localStorage.setItem("netStatus", JSON.stringify(action.status));

      return action.status;

    default:
      if (localStorage.getItem("netStatus") !== null) {
        const netStatus = localStorage.getItem("netStatus");
        return JSON.parse(netStatus);
      }

      return state;
  }
}
