import { useState } from "react";
import KeyIcon from "@mui/icons-material/Key";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import Input from "../../components/Input";
import Label from "../../components/Label";
import { Area, BackgroundImage, LoginArea, ContentLogin } from "./styled";
import api from "../../services/api";
import SnackBar from "../../components/SnackBar";
import LoadingFull from "../../components/LoadingFull";

export default function RecoveryPassword() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const [successSendEmail, setSuccessSendEmail] = useState(false);

  const [loadingFull, setLoadingFull] = useState(false);

  const sendEmailRecover = async (data) => {
    setLoadingFull(true);

    await api
      .post("/email/recover-password", { email: data.email })
      .then(() => {
        setSuccessSendEmail(true);
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  return (
    <>
      <Area>
        <BackgroundImage />
        <LoginArea style={{ flex: 1 }}>
          {!successSendEmail ? (
            <form onSubmit={handleSubmit(sendEmailRecover)}>
              <ContentLogin>
                <h1>Ops, esqueceu sua senha?</h1>
                <h6>
                  Não tem problema, vamos te ajudar a recuperar sua senha!
                </h6>

                <Label>Email</Label>

                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      error={!!errors.email}
                      ref={ref}
                      type="email"
                      inputProps={{
                        inputMode: "email",
                      }}
                      placeholder="Digite o seu email"
                    />
                  )}
                />

                <div style={{ marginTop: "1em", display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Button type="submit" icon={<KeyIcon />}>
                      Recuperar senha
                    </Button>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      alignSelf: "center",
                    }}
                  >
                    <span onClick={() => navigate("/")}>
                      Lembrei minha senha
                    </span>
                  </div>
                </div>
              </ContentLogin>
            </form>
          ) : (
            <ContentLogin style={{ textAlign: "center" }}>
              <CheckCircleIcon
                style={{ fontSize: 120, color: "var(--main-color)" }}
              />
              <h3>Email de verificação enviado com successo</h3>
              <p>
                Aguarde alguns minutos, olhe o seu email e entre no link para
                colocar a sua nova senha.
              </p>
              <span onClick={() => navigate("/")}>Voltar</span>
            </ContentLogin>
          )}
        </LoginArea>
      </Area>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />
    </>
  );
}
