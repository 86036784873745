import { useState } from "react";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Button from "../../../../components/Button";
import ModalLeadPlus from "../ModalLeadPlus";
import api from "../../../../services/api";
import LoadingFull from "../../../../components/LoadingFull";

export default function StepFour({ step, setStep }) {
  const [openModal, setOpenModal] = useState(false);
  const userData = useSelector((state) => state.users);
  const leadData = useSelector((state) => state.leads);
  const visitData = useSelector((state) => state.visits);
  const netStatus = useSelector((state) => state.netStatus);

  const [loadingFull, setLoadingFull] = useState(false);

  const apiCreateVisit = async () => {
    setLoadingFull(true);

    const newVisit = {
      visit: {
        CorrectorId: visitData.selectedCorrector,
        correctorName: visitData.selectedCorrectorName || null,
        salesManager: visitData.selectedSalesManager || null,
        salesCompanyId: visitData.selectedSalesCompanyId || null,
        VisitTypeId: visitData.selectedVisitType,
        day: new Date(),
        LeadId: leadData.id,
      },
      rouletteId: visitData.selectedRoulette,
    };

    await api
      .post("/visit", newVisit, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const storageVisitOffline = () => {
    const newVisit = {
      visit: {
        CorrectorId: visitData.selectedCorrector,
        VisitTypeId: visitData.selectedVisitType,
        day: new Date(),
        uuid: leadData.uuid,
      },
      rouletteId: visitData.selectedRoulette,
    };

    const visits = localStorage.getItem("visits");
    let newVisits = [];

    if (visits) {
      newVisits = [...JSON.parse(visits), newVisit];
    } else {
      newVisits = [newVisit];
    }

    localStorage.setItem("visits", JSON.stringify(newVisits));
  };

  const verifyNetStatusCreateVisit = () => {
    if (netStatus) {
      apiCreateVisit();
    } else {
      storageVisitOffline();
    }
  };

  const createVisit = () => {
    verifyNetStatusCreateVisit();
    setStep(5);
  };

  return (
    <>
      {step === 4 && (
        <div
          style={{
            marginTop: "1em",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            sx={{
              height: 35,
              width: "100%!important",
              backgroundColor: "#EBADAE!important",
              color: "#505050!important",
            }}
            icon={<ArrowBackIcon />}
            invertIcon
            onClick={() => setStep(3)}
          >
            Passo anterior
          </Button>

          <Button
            onClick={() => createVisit()}
            sx={{ width: "100%!important", marginTop: 2 }}
            icon={<AddIcon />}
          >
            Confirmar cadastro
          </Button>
        </div>
      )}

      {step === 5 && (
        <div
          style={{
            marginTop: "1em",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CheckCircleIcon sx={{ color: "var(--main-color)", fontSize: 90 }} />

          <Button
            onClick={() => window.location.reload()}
            sx={{
              width: "100%!important",
              marginTop: 3,
              backgroundColor: "#EBADAE!important",
              color: "#505050!important",
            }}
            icon={<AddIcon />}
          >
            Nova Visita
          </Button>

          <Button
            sx={{ width: "100%!important", marginTop: 2 }}
            icon={<HelpOutlineIcon />}
            onClick={() => setOpenModal(true)}
          >
            Questionário
          </Button>
        </div>
      )}

      <LoadingFull open={loadingFull} setOpen={setLoadingFull} />

      <ModalLeadPlus openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
