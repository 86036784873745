export default function Label({ children, style }) {
  return (
    <p
      style={{
        ...style,
        marginBottom: 0,
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "19px",
        color: "#000000",
        paddingBottom: "4px",
      }}
    >
      {children}
    </p>
  );
}
