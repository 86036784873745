import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { Checkbox, FormControlLabel, Grid, MenuItem } from "@mui/material";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";

import Button from "../../../components/Button";
import BigModal from "../../../components/BigModal";
import {
  Subtitle,
  Title,
  CardSelect,
  CloseButton,
  AreaCardSelect,
} from "./styled";
import Label from "../../../components/Label";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import api from "../../../services/api";
import SnackBar from "../../../components/SnackBar";
import LoadingFull from "../../../components/LoadingFull";
import FloatingButton from "../../../components/FloatingButton";

export default function ModalLeadPlus({
  openModal,
  setOpenModal,
  leadId,
  apiGetAllVisits,
  lead,
}) {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({});
  const userData = useSelector((state) => state.users);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [typeSnackBar, setTypeSnackBar] = useState(""); // error | success

  const [loadingFull, setLoadingFull] = useState(false);

  const [sexo, setSexo] = useState("Feminino");
  const [room, setRoom] = useState("1 dormitório");
  const [veiculo, setVeiculo] = useState("Sim");

  const [completForm, setCompletForm] = useState(false);

  const [contentSelectReason, setContentSelectReason] = useState([]);
  const [contentSelectChannel, setContentSelectChannel] = useState([]);
  const [contentSelectSchooling, setContentSelectSchooling] = useState([]);
  const [contentSelectOccupation, setContentSelectOccupation] = useState([]);

  const completeForm = async (data) => {
    setLoadingFull(true);

    const newInfoLead = {
      sex: sexo || null,
      cep: data.cep?.replace("-", "") || null,
      room: room || null,
      ReasonId: data.procurando || null,
      finance: data.renda || null,
      birthday: data.nascimento || null,
      SchoolingId: data.escolaridade || null,
      car: data.carro
        ? "Sim"
        : veiculo === "Sim"
        ? "Não"
        : veiculo === "Não"
        ? "Não"
        : "Não respondeu",
      motorcycle: data.moto
        ? "Sim"
        : veiculo === "Sim"
        ? "Não"
        : veiculo === "Não"
        ? "Não"
        : "Não respondeu",
      ChannelId: data.canal || null,
      maritalStatus: data.estadoCivil || null,
      street: data.rua || null,
      number: data.numero || null,
      district: data.bairro || null,
      city: data.cidade || null,
      state: data.estado || null,
      observation: data.observation || null,
      OccupationId: data.profissao || null,
    };

    await api
      .put(`/lead/${leadId}`, newInfoLead, {
        headers: { Authorization: `Bearer ${userData.token}` },
      })
      .then((response) => {
        setTypeSnackBar("success");
        setMessageSnackBar(response.data.message);
        setOpenSnackBar(true);

        reset();
        setSexo("Feminino");
        setRoom("1 dormitório");
        setVeiculo("Não respondeu");

        setCompletForm(true);
        apiGetAllVisits();
      })
      .catch((err) => {
        setTypeSnackBar("error");
        setMessageSnackBar(err.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const getAllReasons = async () => {
    let { data } = await api.get("/reason", {
      headers: { Authorization: `Bearer ${userData.token}` },
    });

    setContentSelectReason(data);
  };

  const getAllSchoolings = async () => {
    let { data } = await api.get("/schooling", {
      headers: { Authorization: `Bearer ${userData.token}` },
    });

    setContentSelectSchooling(data);
  };

  const getAllChannels = async () => {
    let { data } = await api.get("/channel", {
      headers: { Authorization: `Bearer ${userData.token}` },
    });

    setContentSelectChannel(data);
  };

  const getAllOccupations = async () => {
    let { data } = await api.get("/occupation", {
      headers: { Authorization: `Bearer ${userData.token}` },
    });

    setContentSelectOccupation(data);
  };

  const searchCep = async (cep) => {
    await axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => {
        setValue("rua", response.data.logradouro);
        setValue("bairro", response.data.bairro);
        setValue("cidade", response.data.localidade);
        setValue("estado", response.data.uf);
        document.getElementById("numero").focus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (watch("cep")) {
      const cep = String(watch("cep")).replaceAll(" ", "").replace("-", "");

      cep.length === 8 && searchCep(cep);
    }
  }, [watch("cep")]);

  useEffect(() => {
    setCompletForm(false);
  }, [openModal]);

  useEffect(() => {
    getAllReasons();
    getAllSchoolings();
    getAllChannels();
    getAllOccupations();
  }, []);

  useEffect(() => {
    setSexo(lead.sex);
    setRoom(lead.room);

    if (lead.car === "Sim" || lead.motorcycle === "Sim") {
      setVeiculo("Sim");
      setValue("carro", lead.car === "Sim" ? true : false);
      setValue("moto", lead.motorcycle === "Sim" ? true : false);
    } else if (
      lead.car === "Não respondeu" &&
      lead.motorcycle === "Não respondeu"
    ) {
      setVeiculo("Não respondeu");
    } else if (lead.car === "Não" || lead.motorcycle === "Não") {
      setVeiculo("Não");
    } else {
      setVeiculo(null);
    }

    setValue("cep", lead.cep || "");
    setValue("rua", lead.street || "");
    setValue("numero", lead.number || "");
    setValue("bairro", lead.district || "");
    setValue("cidade", lead.city || "");
    setValue("estado", lead.state || "");
    setValue("estadoCivil", lead.maritalStatus || "");
    setValue("procurando", lead.ReasonId || "");
    setValue("renda", lead.finance || "");
    setValue("nascimento", lead.birthday || "");
    setValue("escolaridade", lead.SchoolingId || "");
    setValue("canal", lead.ChannelId || "");
    setValue("observation", lead.observation || "");
    setValue("profissao", lead.OccupationId || "");
  }, [lead]);

  if (!openModal) {
    return null;
  }

  return (
    <>
      <BigModal open={openModal} setOpen={setOpenModal}>
        {!completForm ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title>Questionário adicional</Title>

              <CloseButton onClick={() => setOpenModal(false)} />
            </div>
            <Subtitle>
              Essas perguntas adicionais permitem construir um melhor perfil e
              oferecer o melhor atendimento para o cliente
            </Subtitle>

            <Label>Sexo</Label>
            <AreaCardSelect>
              <CardSelect
                onClick={() => setSexo("Feminino")}
                active={sexo === "Feminino"}
              >
                {sexo === "Feminino" ? <CheckCircleIcon /> : <div />}

                <span>Feminino</span>
              </CardSelect>

              <CardSelect
                onClick={() => setSexo("Masculino")}
                active={sexo === "Masculino"}
              >
                {sexo === "Masculino" ? <CheckCircleIcon /> : <div />}

                <span>Masculino</span>
              </CardSelect>
            </AreaCardSelect>

            <Grid container spacing={{ md: 2, xs: 1 }}>
              <Grid item md={3} xs={12}>
                <Label>CEP</Label>

                <Controller
                  name="cep"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field, ref }) => (
                    <NumberFormat
                      customInput={Input}
                      {...field}
                      error={!!errors.cep}
                      ref={ref}
                      placeholder="CEP"
                      format="#####-###"
                    />
                  )}
                />
              </Grid>
              <Grid item md={7} xs={8}>
                <Label>Rua</Label>

                <Controller
                  name="rua"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      ref={ref}
                      error={!!errors.rua}
                      placeholder="Rua"
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} xs={4}>
                <Label>Número</Label>

                <Controller
                  name="numero"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      id="numero"
                      ref={ref}
                      error={!!errors.numero}
                      placeholder="Número"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={{ md: 2, xs: 1 }}>
              <Grid item md={5} xs={12}>
                <Label>Bairro</Label>

                <Controller
                  name="bairro"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      ref={ref}
                      error={!!errors.bairro}
                      placeholder="Bairro"
                    />
                  )}
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <Label>Cidade</Label>

                <Controller
                  name="cidade"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      ref={ref}
                      error={!!errors.cidade}
                      placeholder="Cidade"
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Label>Estado</Label>

                <Controller
                  name="estado"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field, ref }) => (
                    <Select
                      {...field}
                      displayEmpty
                      error={!!errors.estado}
                      ref={ref}
                    >
                      <MenuItem value={""}>
                        <em>Selecione</em>
                      </MenuItem>
                      <MenuItem value="AC">Acre</MenuItem>
                      <MenuItem value="AL">Alagoas</MenuItem>
                      <MenuItem value="AP">Amapá</MenuItem>
                      <MenuItem value="AM">Amazonas</MenuItem>
                      <MenuItem value="BA">Bahia</MenuItem>
                      <MenuItem value="CE">Ceará</MenuItem>
                      <MenuItem value="DF">Distrito Federal</MenuItem>
                      <MenuItem value="ES">Espírito Santo</MenuItem>
                      <MenuItem value="GO">Goiás</MenuItem>
                      <MenuItem value="MA">Maranhão</MenuItem>
                      <MenuItem value="MT">Mato Grosso</MenuItem>
                      <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                      <MenuItem value="MG">Minas Gerais</MenuItem>
                      <MenuItem value="PA">Pará</MenuItem>
                      <MenuItem value="PB">Paraíba</MenuItem>
                      <MenuItem value="PR">Paraná</MenuItem>
                      <MenuItem value="PE">Pernambuco</MenuItem>
                      <MenuItem value="PI">Piauí</MenuItem>
                      <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                      <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                      <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                      <MenuItem value="RO">Rondônia</MenuItem>
                      <MenuItem value="RR">Roraima</MenuItem>
                      <MenuItem value="SC">Santa Catarina</MenuItem>
                      <MenuItem value="SP">São Paulo</MenuItem>
                      <MenuItem value="SE">Sergipe</MenuItem>
                      <MenuItem value="TO">Tocantins</MenuItem>
                      <MenuItem value="EX">Estrangeiro</MenuItem>
                    </Select>
                  )}
                />
              </Grid>
            </Grid>

            <Label>Quantidade de dormitórios</Label>

            <AreaCardSelect>
              <CardSelect
                onClick={() => setRoom("1 dormitório")}
                active={room === "1 dormitório"}
              >
                {room === "1 dormitório" ? <CheckCircleIcon /> : <div />}

                <span>1 dormitório</span>
              </CardSelect>

              <CardSelect
                onClick={() => setRoom("2 dormitórios")}
                active={room === "2 dormitórios"}
              >
                {room === "2 dormitórios" ? <CheckCircleIcon /> : <div />}

                <span>2 dormitórios</span>
              </CardSelect>

              <CardSelect
                onClick={() => setRoom("3 dormitórios")}
                active={room === "3 dormitórios"}
              >
                {room === "3 dormitórios" ? <CheckCircleIcon /> : <div />}

                <span>3 dormitórios</span>
              </CardSelect>

              <CardSelect
                onClick={() => setRoom("3+ dormitórios")}
                active={room === "3+ dormitórios"}
              >
                {room === "3+ dormitórios" ? <CheckCircleIcon /> : <div />}

                <span>3+ dormitórios</span>
              </CardSelect>
            </AreaCardSelect>

            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Label>Estado Cívil</Label>

                <Controller
                  name="estadoCivil"
                  control={control}
                  defaultValue={""}
                  rules={{ required: false }}
                  render={({ field, ref }) => (
                    <Select
                      {...field}
                      displayEmpty
                      error={!!errors.estadoCivil}
                      ref={ref}
                    >
                      <MenuItem value={""}>
                        <em>Qual o estado cívil?</em>
                      </MenuItem>
                      <MenuItem value={"Casado"}>Casado(a)</MenuItem>
                      <MenuItem value={"Divorciado"}>Divorciado(a)</MenuItem>
                      <MenuItem value={"Viúvo"}>Viúvo(a)</MenuItem>
                      <MenuItem value={"Solteiro"}>Solteiro(a)</MenuItem>
                      <MenuItem value={"Noivo"}>Noivo(a)</MenuItem>
                    </Select>
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Label>Porque está procurando um imóvel?</Label>

                <Controller
                  name="procurando"
                  control={control}
                  rules={{ required: false }}
                  defaultValue={""}
                  render={({ field, ref }) => (
                    <Select
                      {...field}
                      displayEmpty
                      error={!!errors.procurando}
                      ref={ref}
                    >
                      <MenuItem value={""}>
                        <em>Qual a razão?</em>
                      </MenuItem>
                      {contentSelectReason?.length > 0 &&
                        contentSelectReason.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Label>Renda familiar</Label>
                <Controller
                  name="renda"
                  control={control}
                  defaultValue={""}
                  rules={{ required: false }}
                  render={({ field, ref }) => (
                    <Select
                      {...field}
                      displayEmpty
                      error={!!errors.renda}
                      ref={ref}
                    >
                      <MenuItem value={""}>
                        <em>Qual a renda familiar?</em>
                      </MenuItem>
                      <MenuItem value={"Até R$ 1.499"}>Até R$ 1.499</MenuItem>
                      <MenuItem value={"De R$ 1.500 a R$ 1.999"}>
                        De R$ 1.500 a R$ 1.999
                      </MenuItem>
                      <MenuItem value={"De R$ 2.000 a R$ 2.499"}>
                        De R$ 2.000 a R$ 2.499
                      </MenuItem>
                      <MenuItem value={"De R$ 2.500 a R$ 2.999"}>
                        De R$ 2.500 a R$ 2.999
                      </MenuItem>
                      <MenuItem value={"De R$ 3.000 a R$ 3.499"}>
                        De R$ 3.000 a R$ 3.499
                      </MenuItem>
                      <MenuItem value={"De R$ 3.500 a R$ 3.999"}>
                        De R$ 3.500 a R$ 3.999
                      </MenuItem>
                      <MenuItem value={"De R$ 4.000 a R$ 4.499"}>
                        De R$ 4.000 a R$ 4.499
                      </MenuItem>
                      <MenuItem value={"De R$ 4.500 a R$ 4.999"}>
                        De R$ 4.500 a R$ 4.999
                      </MenuItem>
                      <MenuItem value={"De R$ 5.000 a R$ 5.499"}>
                        De R$ 5.000 a R$ 5.499
                      </MenuItem>
                      <MenuItem value={"De R$ 5.500 a R$ 5.999"}>
                        De R$ 5.500 a R$ 5.999
                      </MenuItem>
                      <MenuItem value={"De R$ 6.000 a R$ 6.499"}>
                        De R$ 6.000 a R$ 6.499
                      </MenuItem>
                      <MenuItem value={"De R$ 6.500 a R$ 6.999"}>
                        De R$ 6.500 a R$ 6.999
                      </MenuItem>
                      <MenuItem value={"De R$ 7.000 a R$ 7.499"}>
                        De R$ 7.000 a R$ 7.499
                      </MenuItem>
                      <MenuItem value={"De R$ 7.500 a R$ 7.999"}>
                        De R$ 7.500 a R$ 7.999
                      </MenuItem>
                      <MenuItem value={"De R$ 8.000 a R$ 8.499"}>
                        De R$ 8.000 a R$ 8.499
                      </MenuItem>
                      <MenuItem value={"De R$ 8.500 a R$ 8.999"}>
                        De R$ 8.500 a R$ 8.999
                      </MenuItem>
                      <MenuItem value={"De R$ 9.000 a R$ 9.499"}>
                        De R$ 9.000 a R$ 9.499
                      </MenuItem>
                      <MenuItem value={"De R$ 9.500 a R$ 9.999"}>
                        De R$ 9.500 a R$ 9.999
                      </MenuItem>
                      <MenuItem value={"De R$ 10.000 a R$ 11.999"}>
                        De R$ 10.000 a R$ 11.999
                      </MenuItem>
                      <MenuItem value={"De R$ 12.000 a R$ 13.999"}>
                        De R$ 12.000 a R$ 13.999
                      </MenuItem>
                      <MenuItem value={"De R$ 14.000 a R$ 15.999"}>
                        De R$ 14.000 a R$ 15.999
                      </MenuItem>
                      <MenuItem value={"De R$ 16.000 a R$ 17.999"}>
                        De R$ 16.000 a R$ 17.999
                      </MenuItem>
                      <MenuItem value={"De R$ 18.000 a R$ 19.999"}>
                        De R$ 18.000 a R$ 19.999
                      </MenuItem>
                      <MenuItem value={"Acima de R$ 20.000"}>
                        Acima de R$ 20.000
                      </MenuItem>
                    </Select>
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Label>Qual a data de nascimento?</Label>
                <Controller
                  name="nascimento"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      error={!!errors.nascimento}
                      ref={ref}
                      type="date"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Label>Qual o seu nível de escolaridade?</Label>

                <Controller
                  name="escolaridade"
                  control={control}
                  rules={{ required: false }}
                  defaultValue={""}
                  render={({ field, ref }) => (
                    <Select
                      {...field}
                      displayEmpty
                      error={!!errors.escolaridade}
                      ref={ref}
                    >
                      <MenuItem value={""}>
                        <em>Nível de escolaridade</em>
                      </MenuItem>

                      {contentSelectSchooling?.length > 0 &&
                        contentSelectSchooling?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Label>Qual a sua profissão?</Label>

                <Controller
                  name="profissao"
                  control={control}
                  rules={{ required: false }}
                  defaultValue={""}
                  render={({ field, ref }) => (
                    <Select
                      {...field}
                      displayEmpty
                      error={!!errors.profissao}
                      ref={ref}
                    >
                      <MenuItem value={""}>
                        <em>Profissão</em>
                      </MenuItem>

                      {contentSelectOccupation?.length > 0 &&
                        contentSelectOccupation?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>

            <Label>Possui veículo?</Label>
            <AreaCardSelect>
              <CardSelect
                onClick={() => {
                  setVeiculo("Sim");
                  setValue("moto", false);
                  setValue("carro", false);
                }}
                active={veiculo === "Sim"}
              >
                {veiculo === "Sim" ? <CheckCircleIcon /> : <div />}

                <span>Sim</span>
              </CardSelect>

              <CardSelect
                onClick={() => {
                  setVeiculo("Não");
                  setValue("moto", false);
                  setValue("carro", false);
                }}
                active={veiculo === "Não"}
              >
                {veiculo === "Não" ? <CheckCircleIcon /> : <div />}

                <span>Não</span>
              </CardSelect>

              <CardSelect
                onClick={() => {
                  setVeiculo("Não respondeu");
                  setValue("moto", false);
                  setValue("carro", false);
                }}
                active={veiculo === "Não respondeu"}
              >
                {veiculo === "Não respondeu" ? <CheckCircleIcon /> : <div />}

                <span>Não respondeu</span>
              </CardSelect>
            </AreaCardSelect>

            {veiculo === "Sim" && (
              <Grid mt={0} container spacing={2}>
                <Grid
                  item
                  md={6}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <Controller
                    name="carro"
                    control={control}
                    rules={{ required: false }}
                    defaultValue={false}
                    render={({ field, ref }) => (
                      <FormControlLabel
                        {...field}
                        ref={ref}
                        sx={{
                          background: watch("carro")
                            ? "var(--main-color)"
                            : "#EBADAE",
                          color: watch("carro") ? "#FFFFFF" : "grey",
                          gap: "23px",
                          paddingRight: "1em",
                          paddingLeft: ".2em",
                          borderRadius: "4px",
                          height: "45px",
                          width: "100%",
                          margin: 0,
                        }}
                        control={
                          <Checkbox
                            checked={!!field.value}
                            sx={{
                              "&.Mui-checked": {
                                color: watch("carro")
                                  ? "#FFFFFF"
                                  : "var(--main-color)",
                              },
                            }}
                          />
                        }
                        label="Possui carro"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <Controller
                    name="moto"
                    control={control}
                    rules={{ required: false }}
                    defaultValue={false}
                    render={({ field, ref }) => (
                      <FormControlLabel
                        {...field}
                        ref={ref}
                        sx={{
                          background: watch("moto")
                            ? "var(--main-color)"
                            : "#EBADAE",
                          color: watch("moto") ? "#FFFFFF" : "grey",
                          gap: "23px",
                          paddingRight: "1em",
                          paddingLeft: ".2em",
                          borderRadius: "4px",
                          height: "45px",
                          width: "100%",
                          margin: 0,
                        }}
                        control={
                          <Checkbox
                            checked={!!field.value}
                            sx={{
                              "&.Mui-checked": {
                                color: watch("moto")
                                  ? "#FFFFFF"
                                  : "var(--main-color)",
                              },
                            }}
                          />
                        }
                        label="Possui moto"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            <Label>
              Ao procurar um imóvel, que tipo de canal você costuma utilizar?
            </Label>
            <Controller
              name="canal"
              control={control}
              rules={{ required: false }}
              defaultValue={""}
              render={({ field, ref }) => (
                <Select
                  {...field}
                  displayEmpty
                  error={!!errors.canal}
                  ref={ref}
                >
                  <MenuItem value={""}>
                    <em>Canais de busca</em>
                  </MenuItem>
                  {contentSelectChannel?.length > 0 &&
                    contentSelectChannel?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />

            <Label>Observações</Label>
            <Controller
              name="observation"
              control={control}
              rules={{ required: false }}
              defaultValue={""}
              render={({ field, ref }) => (
                <Input
                  {...field}
                  ref={ref}
                  rows={3}
                  multiline
                  error={!!errors.observation}
                  placeholder="Observações"
                />
              )}
            />

            <div
              style={{
                marginTop: "1em",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={handleSubmit(completeForm)}>
                Completar cadastro
              </Button>
            </div>
          </>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h1 style={{ margin: 0 }}>Dados adicionado ao Lead</h1>
            <Button
              onClick={() => setOpenModal(false)}
              sx={{ marginTop: 2, width: "150px!important" }}
            >
              Ok
            </Button>
          </div>
        )}
      </BigModal>

      <SnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={messageSnackBar}
        type={typeSnackBar}
      />

      <LoadingFull open={loadingFull} setOpen={loadingFull} />

      <FloatingButton>Nova Visita</FloatingButton>
    </>
  );
}
