import { Button } from "@mui/material";
import styled from "styled-components";

export const ButtonCustom = styled(Button)`
  background: var(--main-color) !important;
  padding: 0.9em 1.3em !important;
  transition: all 0.3s ease-out !important;
  text-transform: none !important;
  width: max-content !important;

  font-weight: 700 !important;
  font-size: 13px !important;
  color: #ffffff !important;

  svg {
    margin-left: 0.3em;
    margin-right: 0.3em;
    font-size: 20px;
  }

  &:disabled {
    background: grey !important;
    opacity: 0.8;
  }

  :hover {
    opacity: 0.6;
  }
`;
