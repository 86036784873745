import styled from "styled-components";

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0;

  button {
    margin-right: 15px;
  }
`;

export const LogoArea = styled.div`
  @media (max-width: 468px) {
    content: url("/assets/images/logoIcon.png");
  }
`;

export const ProfileArea = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }

  svg {
    align-self: center;
  }

  p {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const DialogArea = styled.div`
  h1 {
    font-size: 22px;
    margin: 0;
    padding-bottom: 0.7em;
  }

  p {
    padding-bottom: 0.5em;
    margin: 0;
  }
`;

export const DialogButtons = styled.div`
  margin-top: 1em;
  float: right;
  display: flex;
  gap: 10px;

  button:first-child {
    background: var(--main-color);
    color: white;
  }

  button {
    font-size: 15px;
    font-family: "Inter";
    border-radius: 50px;
    padding: 0.9em 1.5em;
    border: 0;
    cursor: pointer;

    :hover {
      opacity: 0.6;
    }
  }
`;
